import { MessageBar, MessageBarType } from "office-ui-fabric-react";
import * as React from "react";
import { IErrorBoundaryState } from "./ErrorBoundary.types";

export class ErrorBoundary extends React.Component<{}, IErrorBoundaryState> {
	public static getDerivedStateFromError() {
		return { hasError: true };
	}

	constructor(props: {}) {
		super(props);
		this.state = {
			hasError: false,
			error: undefined,
			errorInfo: undefined
		};
	}

	public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
		this.setState({ ...this.state, error, errorInfo });
	}

	public render() {
		if (this.state.hasError) {
			return (
				<MessageBar
					messageBarType={MessageBarType.error}
					isMultiline={false}
					truncated={true}
					overflowButtonAriaLabel="Show more"
				>
					<div>
						<strong>Something went wrong: </strong>
						{this.state.error?.message}
					</div>
					<div>{this.state.errorInfo?.componentStack}</div>
				</MessageBar>
			);
		}

		return this.props.children;
	}
}
