import { ModernSearch } from "@dlw-mse/main/lib/components/ModernSearch/ModernSearch";
import { isInTeams } from "@dlw-mse/main/lib/utils/ClientType";
import * as MicrosoftTeams from "@microsoft/teams-js";
import { authentication } from "@microsoft/teams-js";
import * as React from "react";
import { getSsoToken, msalAuth } from "./auth/msalAuth";

function App() {

	// Check if the personal search history should be disabled
	const disablePersonalSearchHistory = process.env.REACT_APP_DISABLE_PERSONAL_SEARCH_HISTORY === "true";

	if (isInTeams()) {
		// If in Teams, initialize the Teams SDK and pass the Sso token fetch to the component
		MicrosoftTeams.app.initialize();
		return <ModernSearch apiAccessTokenProvider={authentication.getAuthToken} disablePersonalSearchHistory={disablePersonalSearchHistory} />;
	} else {
		// If not in Teams, redirect to login if needed, otherwise render the component
		if (!msalAuth.getAccount()) {
			msalAuth.loginRedirect();
			return <></>;
		} else {
			return <ModernSearch apiAccessTokenProvider={getSsoToken} userId={msalAuth.getAccount().accountIdentifier} disablePersonalSearchHistory={disablePersonalSearchHistory} />;
		}
	}
}

export default App;
