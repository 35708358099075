import { styled } from "office-ui-fabric-react/lib/Utilities";
import { FeedbackModalBase } from "./FeedbackModal.base";
import { getStyles } from "./FeedbackModal.styles";
import { IFeedbackModalProps, IFeedbackModalStyleProps, IFeedbackModalStyles } from "./FeedbackModal.types";

export const FeedbackModal: React.FC<IFeedbackModalProps> = styled<
	IFeedbackModalProps,
	IFeedbackModalStyleProps,
	IFeedbackModalStyles
>(FeedbackModalBase, getStyles);
