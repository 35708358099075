import { ILoggerService } from "./logger";
import { MockSearchService } from "./mocks";
import { ISearchService } from "./search/ISearchService";
import { SearchService } from "./search/SearchService";



export const createSearchService = (
	loggerService: ILoggerService,
	apiAccessTokenProvider: (() => PromiseLike<string>),
	useMocks?: boolean
): ISearchService => {
	const shouldMock = useMocks !== undefined ? useMocks : process.env.REACT_MOCK_SERVICES === "true";

	return shouldMock ? new MockSearchService() : new SearchService(loggerService, apiAccessTokenProvider);
};
