import { ITheme, loadTheme } from "office-ui-fabric-react";
export const defaultTheme: ITheme = {
  id: "default",
  ...loadTheme({
    semanticColors: {
      bodyBackground: "#F5F5F5",
      bodyText: "#000000",
      bodySubtext: "#484644",
      inputIcon: "#929497"
    },
    fonts: {
      medium: {
        fontFamily: "Segoe UI",
      }
    }
  })
};