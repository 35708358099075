import { IShimmerColors, Shimmer, ShimmerElementsGroup, ShimmerElementType } from "office-ui-fabric-react";
import { classNamesFunction } from "office-ui-fabric-react/lib/Utilities";
import * as React from "react";
import { useTheme } from "../../../models/contexts/ThemeContext";
import { IShimmerItemProps, IShimmerItemStyleProps, IShimmerItemStyles } from "./ShimmerItem.types";

const getClassNames = classNamesFunction<IShimmerItemStyleProps, IShimmerItemStyles>();

export const ShimmerItemBase: React.FC<IShimmerItemProps> = (props) => {
	const {} = props;

	// Styling initialization
	const { className, styles } = props;
	const theme = useTheme();
	const classNames = getClassNames(styles, { className, theme: theme! });

	const shimmerColors: IShimmerColors = {
		shimmer: "#E9E8E8",
		shimmerWave: "#dddddd",
		background: "#f5f5f5"
	};

	const getShimmerItem = () => (
		<div className={classNames.shimmerItem}>
			<ShimmerElementsGroup
				width={"5%"}
				shimmerElements={[
					{ type: ShimmerElementType.line, height: 40, verticalAlign: "top" },
					{ type: ShimmerElementType.gap, height: 70 }
				]}
			/>
			<ShimmerElementsGroup
				flexWrap
				width={"75%"}
				shimmerElements={[
					{ type: ShimmerElementType.line, width: "30%", height: 10 },
					{ type: ShimmerElementType.gap, width: "100%", height: 10 },
					{ type: ShimmerElementType.line, width: "50%", height: 10 },
					{ type: ShimmerElementType.gap, width: "100%", height: 10 },
					{ type: ShimmerElementType.line, width: "100%", height: 10 },
					{ type: ShimmerElementType.gap, width: "100%", height: 10 },
					{ type: ShimmerElementType.line, width: "100%", height: 10 }
				]}
			/>
			<ShimmerElementsGroup
				width={"20%"}
				shimmerElements={[
					{ type: ShimmerElementType.gap, height: 70 },
					{ type: ShimmerElementType.line, height: 70 }
				]}
			/>
		</div>
	);

	return (
		<Shimmer
			className={classNames.root}
			customElementsGroup={getShimmerItem()}
			shimmerColors={shimmerColors}
			width="100%"
		/>
	);
};
