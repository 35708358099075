import { PrimaryButton } from "office-ui-fabric-react/lib/components/Button";
import { DatePicker } from "office-ui-fabric-react/lib/components/DatePicker";
import { IContextualMenuProps } from "office-ui-fabric-react/lib/ContextualMenu";
import { IProcessedStyleSet } from "office-ui-fabric-react/lib/Styling";
import * as React from "react";
import { useSearch } from "../../../reducers";
import { datePickerStyles } from "./DateFilter.styles";
import { IDateFilterProps, IDateFilterStyles } from "./DateFilter.types";

export const useDateFilter = (
	props: IDateFilterProps,
	classNames: IProcessedStyleSet<IDateFilterStyles>
): [menuProps: IContextualMenuProps, selectedFilterText: string] => {
	// Props
	const { fieldName, displayName } = props;
	const { onSelectFilters } = props;
	const { filters } = useSearch();

	// State
	const [selectedFilterText] = React.useState<string>(() => {
		const filter = filters.find((f) => f.fieldName === fieldName);
		if (!!filter) {
			return filter.text;
		} else {
			return "";
		}
	});
	const [fromValue, setFromValue] = React.useState<Date | undefined>(() => {
		const filter = filters.find((f) => f.fieldName === fieldName);
		if (!filter) {
			return undefined;
		}

		const dateString = filter.value.replace("daterage:", "");
		const fromDateString = dateString.split("|")[0];

		if (!fromDateString) {
			return undefined;
		}
		return new Date(fromDateString);
	});
	const [toValue, setToValue] = React.useState<Date | undefined>(() => {
		const filter = filters.find((f) => f.fieldName === fieldName);
		if (!filter) {
			return undefined;
		}

		const dateString = filter.value.replace("daterage:", "");
		const toDateString = dateString.split("|")[1];

		if (!toDateString) {
			return undefined;
		}
		return new Date(toDateString);
	});

	// Render menu list
	const renderMenuList = () => {
		return (
			<div>
				<DatePicker
					placeholder="From"
					ariaLabel="Select a date"
					value={fromValue}
					styles={datePickerStyles}
					onSelectDate={setFromValue as (date: Date | null | undefined) => void}
				/>
				<DatePicker
					placeholder="To"
					ariaLabel="Select a date"
					value={toValue}
					styles={datePickerStyles}
					onSelectDate={setToValue as (date: Date | null | undefined) => void}
				/>
				<PrimaryButton className={classNames.datesButton} text="Apply Dates" onClick={() => onApplyFilter()} />
			</div>
		);
	};
	const menuProps = {
		onRenderMenuList: renderMenuList,
		shouldFocusOnMount: true,
		items: [{ key: "date_picker", text: "date_picker" }]
	};

	// Apply filter
	const onApplyFilter = (): void => {
		const formattedFromDate = fromValue
			? fromValue.getFullYear() + "-" + (fromValue.getMonth() + 1) + "-" + fromValue.getDate()
			: "";
		const formattedToDate = toValue
			? toValue.getFullYear() + "-" + (toValue.getMonth() + 1) + "-" + toValue.getDate()
			: "";

		const formattedDisplayFromDate = fromValue
			? fromValue.getDate() + "/" + (fromValue.getMonth() + 1) + "/" + fromValue.getFullYear()
			: "";
		const formattedDisplayToDate = toValue
			? toValue.getDate() + "/" + (toValue.getMonth() + 1) + "/" + toValue.getFullYear()
			: "";

		const filterValue = `daterange:${formattedFromDate}|${formattedToDate}`;
		const filterText = `From ${!!formattedDisplayFromDate ? formattedDisplayFromDate : "..."} To ${
			!!formattedDisplayToDate ? formattedDisplayToDate : "..."
		}`;
		onSelectFilters(fieldName, [
			{
				fieldName: fieldName,
				displayName: displayName,
				text: filterText,
				key: `datefilter-${fieldName}`,
				value: filterValue,
				count: -1
			}
		]);
	};
	return [menuProps, selectedFilterText];
};
