import { keyframes } from "office-ui-fabric-react/lib/Styling";
import { IShimmerItemStyleProps, IShimmerItemStyles } from "./ShimmerItem.types";

const GlobalClassNames = {
	root: "dlw-ShimmerItem-root",
	shimmerItem: "dlw-ShimmerItem-shimmerItem"
};

const slideInRight = keyframes({
	"0%": {
		left: "-25px"
	},
	"100%": {
		left: "0px"
	}
});

export const getStyles = (props: IShimmerItemStyleProps): IShimmerItemStyles => {
	const { className, theme } = props;
	const classNames = GlobalClassNames;

	return {
		root: [classNames.root, {
			marginBottom: "20px",
			position: "relative",
			animationName: slideInRight,
			animationDuration: "0.5s",
		}, className],
		shimmerItem: [classNames.shimmerItem, {
			display: "flex",
			".ms-ShimmerGap-root": {
				backgroundColor: theme.semanticColors.bodyBackground
			},
			".ms-ShimmerLine-root": {
				borderBottomColor: theme.semanticColors.bodyBackground
			}
		}]
	};
};
