import { AppInsightsContext, withAITracking } from "@microsoft/applicationinsights-react-js";
import { app, pages } from "@microsoft/teams-js";
import { ITheme } from "office-ui-fabric-react";
import { classNamesFunction } from "office-ui-fabric-react/lib/Utilities";
import * as React from "react";
import Globals from "../../Globals";
import { ApiAccessTokenProviderContext, IDictionary, ThemeContext, ViewType, ViewTypeContext } from "../../models";
import { SearchProvider } from "../../reducers";
import { IconService } from "../../services/icons/IconService";
import { ILoggerService, LoggerContext, LoggerService } from "../../services/logger";
import { ProfileContext } from "../../services/profile/ProfileContext";
import { ProfileService } from "../../services/profile/ProfileService";
import { contrastTheme } from "../../themes/Contrast";
import { darkTheme } from "../../themes/Dark";
import { defaultTheme } from "../../themes/Default";
import { isInTeams } from "../../utils";
import { reactPlugin } from "../../utils/AppInsights";
import { MsClarityProvider } from "../../utils/MsClarityProvider";
import { LandingView, ResultsView } from "../../views";
import { AuthenticationModal } from "../AuthenticationModal/AuthenticationModal";
import { ErrorBoundary } from "../ErrorBoundry/ErrorBoundary";
import { IModernSearchProps, IModernSearchStyleProps, IModernSearchStyles } from "./ModernSearch.types";

const getClassNames = classNamesFunction<IModernSearchStyleProps, IModernSearchStyles>();

const iconService = new IconService();

const ModernSearchBase: React.FC<IModernSearchProps> = (props) => {
	// Get theme from query string
	const themeParam = new URLSearchParams(window.location.search).get("theme") || "default";

	// Init hooks and props
	const { apiAccessTokenProvider, disablePersonalSearchHistory } = props;
	const [viewType, setViewType] = React.useState<ViewType>(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const query = urlParams.get("query");
		if (!!query) {
			return ViewType.results;
		}
		return ViewType.landing;
	});
	const [theme, setTheme] = React.useState<ITheme>(themeParam === "dark" ? darkTheme : defaultTheme);
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [loggerService, setLoggerService] = React.useState<ILoggerService | undefined>(undefined);
	const [profileProperties, setProfileProperties] = React.useState<IDictionary | undefined>(undefined);
	const inTeams = isInTeams();

	// Update the theme when the context changes
	const updateTheme = (newTheme: string) => {
		switch (newTheme) {
			default:
			case "default":
				setTheme(defaultTheme);
				break;
			case "dark":
				setTheme(darkTheme);
				break;
			case "contrast":
				setTheme(contrastTheme);
				break;
		}
	};

	// Load the app context and set the default theme
	React.useEffect(() => {
		// Init Teams
		const initTeams = async () => {
			app.registerOnThemeChangeHandler((newTheme: string) => {
				updateTheme(newTheme);
			});
			const context = await app.getContext();
			app.registerOnThemeChangeHandler((newTheme: string) => updateTheme(newTheme));

			// Init services
			const profileSvc = new ProfileService(apiAccessTokenProvider);
			const profile = await profileSvc.getProfileProperties();
			const logService = new LoggerService({ userId: context.user?.id ?? "", teams: "1" }, profile);

			// Register app icon click event
			pages.appButton.onClick(() => {
				logService?.trackEvent(Globals.Telemetry.Events.ON_TEAMS_APP_ICON_CLICK);
				window.location.href = process.env.REACT_APP_BROWSER_URL ?? "/";
			});

			logService.trackPageView();
			setLoggerService(logService);
			setProfileProperties(profile);
			setIsLoading(false);
			updateTheme(context.app.theme);
		};

		const initWeb = async () => {
			// Init services
			const profileSvc = new ProfileService(apiAccessTokenProvider);
			const profile = await profileSvc.getProfileProperties();
			const logService = new LoggerService({ userId: props?.userId ?? "", teams: "0" }, profile);

			logService.trackPageView();
			setLoggerService(logService);
			setProfileProperties(profile);
			setIsLoading(false);
		};

		// Init MS Clarity
		MsClarityProvider.setupMsClarity(process.env.REACT_APP_MS_CLARITY_ID, props.userId);

		// Load the context if in teams
		if (inTeams) {
			initTeams();
		} else {
			initWeb();
		}
	}, []);

	// Return if context is not loaded
	if (isLoading) {
		return <></>;
	}

	// Init styles and icons
	iconService.registerCustomIcons();
	const { className, styles } = props;
	const classNames = getClassNames(styles, { className, theme: theme! });

	return (
		<AppInsightsContext.Provider value={reactPlugin}>
			<ThemeContext.Provider value={theme}>
				<ViewTypeContext.Provider value={[viewType, setViewType]}>
					<ApiAccessTokenProviderContext.Provider value={apiAccessTokenProvider}>
						<LoggerContext.Provider value={loggerService}>
							<ProfileContext.Provider value={profileProperties}>
								<ErrorBoundary>
									<SearchProvider>
										<div className={classNames.root}>
											{viewType === ViewType.landing && <LandingView disablePersonalSearchHistory={disablePersonalSearchHistory} />}
											{viewType === ViewType.results && <ResultsView disablePersonalSearchHistory={disablePersonalSearchHistory} />}
										</div>
										<AuthenticationModal />
									</SearchProvider>
								</ErrorBoundary>
							</ProfileContext.Provider>
						</LoggerContext.Provider>
					</ApiAccessTokenProviderContext.Provider>
				</ViewTypeContext.Provider>
			</ThemeContext.Provider>
		</AppInsightsContext.Provider>
	);
};

export default withAITracking(reactPlugin, ModernSearchBase);
