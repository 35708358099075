export class StringUtility {
	public static getWordsFromString (input: string): string[] {
		const cleanInput = input.replace(/[{()}]/g, " ");
		const regexMatches = cleanInput.match(/(\b[^\s]+\b)/g);
		if (regexMatches) {
			return regexMatches
				.map(match => match.trim())
				.filter((item: string, i: number, ar: string[]) => ar.indexOf(item) === i);
		} else {
			return [];
		}
	}
}