import { Modal } from "office-ui-fabric-react/lib/components/Modal";
import { Overlay } from "office-ui-fabric-react/lib/components/Overlay";
import { classNamesFunction } from "office-ui-fabric-react/lib/Utilities";
import * as React from "react";
import { useTheme } from "../../models/contexts/ThemeContext";
import { getModalStyles } from "./ImecModal.styles";
import { IImecModalProps, IImecModalStyleProps, IImecModalStyles } from "./ImecModal.types";

const getClassNames = classNamesFunction<IImecModalStyleProps, IImecModalStyles>();

export const ImecModalBase: React.FC<IImecModalProps> = (props) => {
	// Styling initialization
	const { className, maxWidth, minWidth, maxHeight, minHeight, styles } = props;
	const theme = useTheme();
	const classNames = getClassNames(styles, { className, theme: theme! });

	return (
		<>
			{props.isOpen && (
				<Overlay className={classNames.root}>
					<Modal {...props} styles={getModalStyles(maxWidth, minWidth, maxHeight, minHeight)}>
						{props.children}
					</Modal>
				</Overlay>
			)}
		</>
	);
};
