import { classNamesFunction } from "office-ui-fabric-react/lib/Utilities";
import * as React from "react";
import Globals from "../../Globals";
import { useViewType } from "../../models";
import { useTheme } from "../../models/contexts/ThemeContext";
import { useSearch } from "../../reducers";
import { useLoggerService } from "../../services/logger";
import Symbols from "../../svg-symbols/Symbols";
import { isInTeams } from "../../utils";
import { SearchBar } from "../SearchBar";
import { ISearchContainerProps, ISearchContainerStyleProps, ISearchContainerStyles } from "./SearchContainer.types";

const getClassNames = classNamesFunction<ISearchContainerStyleProps, ISearchContainerStyles>();

export const SearchContainerBase: React.FC<ISearchContainerProps> = (props) => {
	const { onExecuteSearch } = props;
	const { suggestionResponse, currentQuery, isSearching, queryModification, searchResult, submitQuery, clearSuggest } = useSearch();
	const { isQueryModificationRequest } = queryModification;
	const loggerService = useLoggerService();

	const browserUrl = process.env.REACT_APP_BROWSER_URL;
	const fullUrl = `${browserUrl}${window.location.search}`;
	const inTeams = isInTeams();
	const [viewType] = useViewType();

	// Styling initialization
	const { className, styles, title, big, disablePersonalSearchHistory } = props;
	const theme = useTheme();
	const classNames = getClassNames(styles, { className, theme: theme! });

	const handleClear = () => {
		clearSuggest();
	};

	const handleSearch = (searchText: string, includeSuggestions: boolean) => {
		if (!!onExecuteSearch) {
			onExecuteSearch(searchText);
		}
		clearSuggest();
		submitQuery(searchText, includeSuggestions);
	};

	const onOpenInBrowserClick = (url: string): void => {
		loggerService?.trackEvent(Globals.Telemetry.Events.ON_OPEN_IN_BROWSER_CLICK, { payload: viewType.toString() });
		window.open(url, "_blank");
	};

	// Hide suggestions if total count is greater then suggestion threshold or the search is being executed
	const total = searchResult?.totalCount ?? 0;
	const hideSuggestions = isSearching || total > (suggestionResponse?.disableSuggestionsThreshold ?? 0);
	const suggestionsToShow = total == 0
		? (suggestionResponse?.noResultsSuggestions ?? [])
		: (suggestionResponse?.suggestions ?? []);

	return (
		<div className={classNames.root}>
			<div className={big ? classNames.wrapper : classNames.wrapperSmall}>
				<div className={classNames.searchBoxContainer}>
					{browserUrl && inTeams && (
						<div className={classNames.openBrowser}>
							<svg className={classNames.worldIcon}>
								<use xlinkHref={Symbols.Icons.IconWorld} />
							</svg>
							<a
								href={`${browserUrl}${window.location.search}`}
								target={"_blank"}
								onClick={(ev) => {
									ev.preventDefault();
									onOpenInBrowserClick(fullUrl);
								}}
							>
								{"Open in your browser"}
							</a>
						</div>
					)}
					<div className={classNames.searchTitle}>{title}</div>
					<SearchBar disablePersonalSearchHistory={disablePersonalSearchHistory} onSearch={handleSearch} onClear={handleClear} value={currentQuery} />
				</div>

				{isSearching && isQueryModificationRequest && (
					<div className={classNames.querySuggestionContainer}>
						{`No results found for `}<i>{searchResult?.originalQuery}</i>{`. Searching for `}<i><u>{currentQuery}</u></i>{` instead.`}
					</div>
				)}

				{!isSearching && searchResult?.isQueryModificationResult && (
					<>
						{total === 0 ?
							(
								<div className={classNames.querySuggestionContainer}>
									{`No results found for `}<i>{searchResult?.queryModification.originalQueryText}</i>{`. Tried searching for `}<i><u>{currentQuery}</u></i>{` instead.`}
								</div>
							) : (
								<div className={classNames.querySuggestionContainer}>
									{`No results found for `}<i>{searchResult?.queryModification.originalQueryText}</i>{`. Showing results for `}<i><u>{currentQuery}</u></i>{` instead.`}
								</div>
							)}
					</>
				)}

				<div className={classNames.querySuggestionContainer}>
					{!isSearching && !searchResult?.isQueryModificationResult && suggestionResponse && suggestionsToShow.length > 0 && !hideSuggestions && (
						<>
							<div>Did you mean </div>
							{suggestionsToShow.map((it, index) => (
								<React.Fragment key={index}>
									<a
										key={index}
										className={classNames.querySuggestion}
										onClick={() => {
											loggerService?.trackEvent(Globals.Telemetry.Events.ON_SUGGESTION_CLICK, {
												payload: `${it.suggestion}${it.fromGraph ? " (from Graph)" : " (from Keywords)"}`
											});
											handleSearch(it.suggestion, false);
										}}
										dangerouslySetInnerHTML={{
											__html: `"<u>${it.highlightedSuggestion
												.replace("\uE000", "<strong>")
												.replace("\uE001", "</strong>")}</u>"`
										}}
									/>
									<span>?</span>
									<br />
								</React.Fragment>
							))}
						</>
					)}
				</div>
			</div>
		</div>
	);
};
