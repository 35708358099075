import Globals from "../../Globals";
import { ILoggerService } from "../logger";
export abstract class SearchServiceBase {
	public ApiAccessTokenProvider: (() => PromiseLike<string>);
  public loggerService: ILoggerService;

	constructor(
    loggerService: ILoggerService,
    apiAccessTokenProvider: (() => PromiseLike<string>)
  ) {
    this.loggerService = loggerService;
		this.ApiAccessTokenProvider = apiAccessTokenProvider;
	}

	protected async executePost<T, B>(action: string, body: B, parameters?: string): Promise<T | void> {
        
    // Fetch authentication token
    const accessToken = await this.ApiAccessTokenProvider();

    // Build URL
    let urlPathName = action;
    if (parameters) {
      urlPathName += `?${parameters}`;
    }

		// Metric start
		const startTime = performance.now();

    // Execute fetch
    const response = await fetch(
      urlPathName,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,

        },
        body: JSON.stringify(body),
        method: "POST",
      }
    );

		// Metric end
		const endTime = performance.now();

		// Metric track
    this.loggerService?.trackMetric(Globals.Telemetry.Metrics.FE_API_SEARCH_TIME, endTime - startTime);

    // Check response
    if (!response.ok) {
      throw new Error(await response.text());
    }

    if (response.status === 204) {
      return;
    }

    // Return response
    return response.json();
  }

  protected async executeGet<T>(action: string, parameters?: string): Promise<T | void> {
        
    // Fetch authentication token
    const accessToken = await this.ApiAccessTokenProvider();

    // Build URL
    let urlPathName = action;
    if (parameters) {
      urlPathName += `?${parameters}`;
    }

		// Metric start
		const startTime = performance.now();

    // Execute fetch
    const response = await fetch(
      urlPathName,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,

        },
        method: "GET",
      }
    );

		// Metric end
		const endTime = performance.now();

		// Metric track
    this.loggerService?.trackMetric(Globals.Telemetry.Metrics.FE_API_SUGGEST_TIME, endTime - startTime);

    // Check response
    if (!response.ok) {
      throw new Error(await response.text());
    }

    if (response.status === 204) {
      return;
    }

    // Return response
    return response.json();
  }
}