export enum ClientType {
	Browser,
	TeamsClientApp,
	TeamsClientAppAndroid,
	TeamsClientAppIos,
	TeamsClientTab,
	TeamsBrowser,
	SharePointMobileApp
}

export const getClientType = (): ClientType => {
	const userAgent: string = window.navigator.userAgent.toLocaleLowerCase();

	if (userAgent.indexOf("sharepoint for") > -1) {
		return ClientType.SharePointMobileApp;
	} else if (userAgent.indexOf("teams") > -1 &&
		userAgent.indexOf("electron") > -1 &&
		window.location !== window.parent.location) {
		return ClientType.TeamsClientApp;
	} else if (userAgent.indexOf("teams") > -1 && userAgent.indexOf("electron") > -1) {
		return ClientType.TeamsClientTab;
	}
	else if (userAgent.indexOf("teamsmobile-android") > -1) {
		return ClientType.TeamsClientAppAndroid;
	} 
	else if (userAgent.indexOf("teamsmobile-ios") > -1) {
		return ClientType.TeamsClientAppIos;
	}
	else if (window.location !== window.parent.location) {
		return ClientType.TeamsBrowser;
	} else {
		return ClientType.Browser;
	}
};

export const isInTeams = (): boolean => {
	const clientType = getClientType();

	return (
		clientType === ClientType.TeamsClientApp ||
		clientType === ClientType.TeamsClientAppAndroid ||
		clientType === ClientType.TeamsClientAppIos ||
		clientType === ClientType.TeamsClientTab ||
		clientType === ClientType.TeamsBrowser
	);
};