import { IQuickLink } from "../../models/IQuickLinks";
import { IQuickLinkService } from "./IQuickLinkService";

export class QuickLinkService implements IQuickLinkService {
	public ApiAccessTokenProvider: (() => PromiseLike<string>);
	
	constructor(apiAccessTokenProvider: (() => PromiseLike<string>)) {
		this.ApiAccessTokenProvider = apiAccessTokenProvider;
	}

	public getQuickLinks = async (): Promise<IQuickLink[]> => {
		// Fetch authentication token
    const accessToken = await this.ApiAccessTokenProvider();

		const url = process.env.REACT_APP_WEBAPI_BASEURI ?? "";
    // Execute fetch
    const response = await fetch(
      `${url}/api/Settings/getquicklinks?fromCache=true`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,

        },
        method: "GET",
      }
    );

    // Check response
    if (!response.ok) {
      throw new Error(await response.text());
    }

    // Return response
    return response.json();
	};
}