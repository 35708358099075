import { DefaultButton } from "office-ui-fabric-react/lib/Button";
import { classNamesFunction } from "office-ui-fabric-react/lib/Utilities";
import * as React from "react";
import { useTheme } from "../../../models/contexts/ThemeContext";
import { contextualMenuStyles } from "./DateFilter.styles";
import { IDateFilterProps, IDateFilterStyleProps, IDateFilterStyles } from "./DateFilter.types";
import { useDateFilter } from "./UseDateFilter";

const getClassNames = classNamesFunction<IDateFilterStyleProps, IDateFilterStyles>();

export const DateFilterBase: React.FC<IDateFilterProps> = (props) => {
	// Styling initialization
	const { className, displayName, styles } = props;
	const theme = useTheme();
	const classNames = getClassNames(styles, { className, theme: theme! });
	const [menuProps, selectedFilterText] = useDateFilter(props, classNames);

	return (
		<div className={classNames.root}>
			<span className={classNames.label}>{displayName}</span>
			<DefaultButton
				className={classNames.button}
				onRenderText={() => <div className={classNames.contextualMenuLabel}>{selectedFilterText}</div>}
				styles={contextualMenuStyles(theme)}
				menuProps={menuProps}
			/>
		</div>
	);
};
