import { IModalStyles } from "office-ui-fabric-react/lib/components/Modal";
import { IImecModalStyleProps, IImecModalStyles } from "./ImecModal.types";

const GlobalClassNames = {
	root: "mse-ImecModal-root"
};

export const getStyles = (props: IImecModalStyleProps): IImecModalStyles => {
	const { className } = props;
	const classNames = GlobalClassNames;

	return {
		root: [classNames.root, { zIndex: 9 }, className]
	};
};

export const getModalStyles = (
	maxWidth?: string,
	minWidth?: string,
	maxHeight?: string,
	minHeight?: string
): IModalStyles => {
	return {
		main: {
			minWidth: minWidth,
			maxWidth: maxWidth,
			minHeight: minHeight,
			maxHeight: maxHeight,
			borderRadius: "9px"
		},
		root:{},
		keyboardMoveIcon:{},
		keyboardMoveIconContainer:{},
		layer:{},
		scrollableContent:{}
	};
}
