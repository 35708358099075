import { classNamesFunction } from "office-ui-fabric-react/lib/Utilities";
import * as React from "react";
import { useTheme } from "../../models/contexts/ThemeContext";
import { FilterType } from "../../models/search/Filter";
import { DateFilter } from "./DateFilter/DateFilter";
import { ProfileFilter } from "./ProfileFilter/ProfileFilter";
import { ISearchFilterProps, ISearchFilterStyleProps, ISearchFilterStyles } from "./SearchFilter.types";
import { TextFilter } from "./TextFilter/TextFilter";

const getClassNames = classNamesFunction<ISearchFilterStyleProps, ISearchFilterStyles>();

export const SearchFilterBase: React.FC<ISearchFilterProps> = (props: ISearchFilterProps) => {
	// Props
	const { filterType } = props;

	// Styling initialization
	const { className, styles } = props;
	const theme = useTheme();
	const classNames = getClassNames(styles, { className, theme: theme! });

	const renderFilters = () => {
		switch (filterType) {
			case FilterType.Date:
				return <DateFilter {...props} />;
			case FilterType.Text:
				return <TextFilter {...props} />;
			case FilterType.Profile:
				return <ProfileFilter {...props} />;
			default:
				return <></>;
		}
	};

	return <div className={classNames.root}>{renderFilters()}</div>;
};
