import { Icon, MessageBar, MessageBarType } from "office-ui-fabric-react";
import { classNamesFunction } from "office-ui-fabric-react/lib/Utilities";
import * as React from "react";
import Globals from "../../Globals";
import { useTheme } from "../../models/contexts/ThemeContext";
import { useSearch } from "../../reducers";
import RenderIf from "../../utils/RenderIf";
import { FeedbackModal } from "../FeedbackModal";
import { InfiniteScroll } from "../InfiniteScroll";
import { NoResults } from "../NoResults/NoResults";
import { SearchFiltersContainer } from "../SearchFiltersContainer";
import { SearchResultItem } from "../SearchResultItem";
import {
	ISearchResultsListProps,
	ISearchResultsListStyleProps,
	ISearchResultsListStyles
} from "./SearchResultsList.types";

const getClassNames = classNamesFunction<ISearchResultsListStyleProps, ISearchResultsListStyles>();

export const SearchResultsListBase: React.FC<ISearchResultsListProps> = (props) => {
	const {} = props;
	const { searchResult, isSearching, page, error, setPage } = useSearch();

	// Styling initialization
	const { className, styles } = props;
	const theme = useTheme();
	const classNames = getClassNames(styles, { className, theme: theme! });

	// State
	const [isNegativeFeedbackOpen, setIsNegativeFeedbackOpen] = React.useState<boolean>(false);
	const [isPositiveFeedbackOpen, setIsPositiveFeedbackOpen] = React.useState<boolean>(false);

	const loadMore = (currentPage: number) => {
		setPage(currentPage + 1);
	};

	let totalCountLabel = "";
	if (searchResult) {
		if (searchResult && searchResult.roundedCount) {
			totalCountLabel = "About ";
		}

		let resultsLabel = "result";
		if (searchResult && searchResult.totalCount !== 1) {
			resultsLabel = `${resultsLabel}s`;
		}

		totalCountLabel = `${totalCountLabel}${searchResult.totalCount?.toLocaleString(
			Globals.Formatting.NumberFormatLocale
		)} ${resultsLabel}`;
	}

	return (
		<>
			<div className={classNames.root}>
				{!!error ? (
					<MessageBar messageBarType={MessageBarType.error} truncated={true} isMultiline={false}>
						<strong>{error.message}</strong>
						<div>{error.stack}</div>
					</MessageBar>
				) : (
					<>
						{searchResult && (
							<div>
								<SearchFiltersContainer />
								{!isSearching && (
									<div className={classNames.resultsRow}>
										<div>
											<span className={classNames.resultCount}>{totalCountLabel} found</span>
										</div>
										{searchResult?.items.length > 0 && (
											<div className={classNames.resultsRowFeedback}>
												<span>Feedback?</span>
												<Icon iconName={"ImecEmojiLaugh"} onClick={() => setIsPositiveFeedbackOpen(true)} />
												<Icon iconName={"ImecEmojiSad"} onClick={() => setIsNegativeFeedbackOpen(true)} />
											</div>
										)}
									</div>
								)}
								<hr className={classNames.seperator} />
								<InfiniteScroll
									hasMoreData={searchResult.moreResultsAvailable}
									isLoading={isSearching}
									onBottomHit={loadMore}
									currentPage={page}
								>
									{searchResult?.items.map((item, index) => (
										<React.Fragment key={`${searchResult?.currentPage}-${item.type}-${index}`}>
											<SearchResultItem item={item} synonyms={searchResult.synonyms} />
										</React.Fragment>
									))}
								</InfiniteScroll>
							</div>
						)}
						<RenderIf condition={searchResult?.items.length === 0 && !isSearching}>
							<NoResults />
						</RenderIf>
					</>
				)}
			</div>
			<FeedbackModal isOpen={isNegativeFeedbackOpen} onClose={() => setIsNegativeFeedbackOpen(false)} />
			<FeedbackModal isOpen={isPositiveFeedbackOpen} onClose={() => setIsPositiveFeedbackOpen(false)} isPositive />
		</>
	);
};
