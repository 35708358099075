import { IDropdownStyles } from "office-ui-fabric-react/lib/Dropdown";
import { ITextFilterStyleProps, ITextFilterStyles } from "./TextFilter.types";

const GlobalClassNames = {
	root: "mse-TextFilter",
	dropdown: "mse-TextFilter-dropdown",
	dropdownLabel: "mse-TextFilter-dropdownLabel",
	dropdownCallout: "mse-TextFilter-dropdownCallout",
	dropdownOption: "mse-TextFilter-dropdownOption",
	dropdownOptionCloseIcon: "mse-TextFilter-dropdownOptionCloseIcon"
};

export const getStyles = (props: ITextFilterStyleProps): ITextFilterStyles => {
	const { className, theme } = props;
	const classNames = GlobalClassNames;

	return {
		root: [classNames.root, {}, className],
		dropdownLabel: [
			classNames.dropdownLabel,
			{
				fontFamily: theme.fonts.medium.fontFamily,
				fontStyle: "normal",
				fontWeight: "400",
				fontSize: "12px",
				lineHeight: "16px",
				color: theme.semanticColors.bodySubtext,
				textAlign: "left"
			}
		],
		dropdown: [
			classNames.dropdown, {
				border: "none",
				borderRadius: "3px",
				"& ms-Dropdown-title": {
					border: "none",
					borderRadius: "3px",
				},
				"& ms-Dropdown": {
					border: "none",
					borderRadius: "3px",
					paddingTop: "3px"
				}
				
			}
		],
		dropdownCallout: [
			classNames.dropdownCallout, {
				" .ms-Checkbox-checkbox:last-of-type": {
					display: "none"
				},
				"button": {
					width: "100%",
					position: "relative",
    			top: "5px"
				},
				" > div": {
					paddingBottom: "12px"
				}
			}
		],
		dropdownOption: [
			classNames.dropdownOption, {
				display: "flex",
				alignItems: "center",
				overflow: "hidden",
				textOverflow: "ellipsis",
				whiteSpace: "nowrap",
				marginRight: "20px",
				">i": {
					height: "30px",
					marginRight: "10px",
					marginLeft: "5px"
				},
				">span": {
					overflow: "hidden",
					textOverflow: "ellipsis",
					whiteSpace: "nowrap"
				}
			}
		],
		dropdownOptionCloseIcon: [classNames.dropdownOptionCloseIcon, {
			top: "0",
			right: "0",
			position: "fixed",
			fontSize: "10px",
			width: "30px",
			textAlign: "center",
			">i": {
				width: "100%",
				height: "36px",
				lineHeight: "36px",
				background: "#edebe9"
			}
		}]
	};
};

export const dropdownStyles: Partial<IDropdownStyles> = {
	dropdown: {
		border: "none",
		borderRadius: "3px",
		marginTop: "3px"
	},
	title: {
		border: "none",
		borderRadius: "3px",
		"&:focus, &:hover": {
			border: "none"
		},
		"&:active": {
			border: "none"
		},
		"&::after": {
			border: "none"
		}
	},
	callout: {
		boxShadow: "none",
		margintTop: "-2px"
	}
};
