import { ILoadingShimmerStyleProps, ILoadingShimmerStyles } from "./LoadingShimmer.types";

const GlobalClassNames = {
	root: "dw-LoadingShimmer",
	shimmerItem: "dw-LoadingShimmer-shimmerItem"
};

export const getStyles = (props: ILoadingShimmerStyleProps): ILoadingShimmerStyles => {
	const { className, theme } = props;
	const classNames = GlobalClassNames;

	return {
		root: [classNames.root, {}, className],
		shimmerItem: [classNames.shimmerItem, {
			display: "flex",
			".ms-ShimmerGap-root": {
				backgroundColor: theme.semanticColors.bodyBackground
			},
			".ms-ShimmerLine-root": {
				borderBottomColor: theme.semanticColors.bodyBackground
			}
		}]
	};
};
