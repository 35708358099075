import { styled } from "office-ui-fabric-react/lib/Utilities";
import { TextFilterBase } from "./TextFilter.base";
import { getStyles } from "./TextFilter.styles";
import { ITextFilterProps, ITextFilterStyleProps, ITextFilterStyles } from "./TextFilter.types";

export const TextFilter: React.FC<ITextFilterProps> = styled<
	ITextFilterProps,
	ITextFilterStyleProps,
	ITextFilterStyles
>(TextFilterBase, getStyles);
