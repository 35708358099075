import { SeverityLevel } from "@microsoft/applicationinsights-web";
import Globals from "../../Globals";
import { IDictionary } from "../../models/IDictionary";
import { appInsights } from "../../utils/AppInsights";
import { ILoggerService } from "./ILoggerService";

export class LoggerService implements ILoggerService {

	private _defaultProperties: IDictionary = {
    Source: "imec-search-js"
  };

	constructor(properties: IDictionary, profile?: IDictionary) {
		this._defaultProperties = { ...this._defaultProperties, ...properties };
    if (!!profile) {
      this._defaultProperties = { ...this._defaultProperties, ...profile}
    }
	}

	public trackPageView = (properties?: IDictionary): void => {
    appInsights.trackPageView({
      name: window.location.pathname,
      uri: window.location.href,
      properties: {
        ...properties,
        ...this._defaultProperties
      }
    });
  }

  public trackTrace = (
		message: string, properties?: IDictionary, severityLevel: SeverityLevel = SeverityLevel.Information
	): void => {
    appInsights.trackTrace({
      message, severityLevel, properties: {
        ...properties,
        ...this._defaultProperties
      }
    });
  }

  public trackEvent = (name: string, properties?: IDictionary): void => {
    // Add queryId to all events
    const queryId = window.sessionStorage.getItem(Globals.CacheKeys.QUERY_ID);
    appInsights.trackEvent({
      name, properties: {
        ...properties,
        ...this._defaultProperties,
        QueryId: queryId
      }
    });
  }

  public trackMetric = (name: string, average: number, properties?: IDictionary): void => {
    appInsights.trackMetric({
      name,
      average,
      sampleCount: 1,
      properties: {
        ...properties,
        ...this._defaultProperties
      }
    });
  }

  public trackException = (err: Error, properties?: IDictionary): Error => {
    const userErrorCode = this.generateUserErrorCode(10);
    appInsights.trackException({
      exception: err, properties: {
        userErrorCode,
        ...properties,
        ... this._defaultProperties
      }
    });
    return {
      ...err,
      message: `Error ${userErrorCode} - ${err.message}`
    };
  }

  private generateUserErrorCode = (length: number): string => {
    const arr = new Uint8Array((length || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, (d) => (d.toString(16).padStart(2, "0"))).join("");
  }
}