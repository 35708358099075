import { DefaultButton, DropdownMenuItemType, Icon, IProcessedStyleSet, PrimaryButton } from "office-ui-fabric-react";
import * as React from "react";
import Globals from "../../../Globals";
import { IFilterValue } from "../../../models/search/Filter";
import { IFilterDropdownOption } from "../../../models/search/FilterDropdownOption";
import { ISearchFilter } from "../../../models/SearchFilter";
import { useSearch } from "../../../reducers";
import { useLoggerService } from "../../../services/logger";
import { ITextFilterProps, ITextFilterStyles } from "./TextFilter.types";

export const useTextFilter = (
	props: ITextFilterProps,
	classNames: IProcessedStyleSet<ITextFilterStyles>
): [
	dropdownOptions: IFilterDropdownOption[],
	selectedOptions: IFilterDropdownOption[],
	isDisabled: boolean,
	onRenderDropdownOption: (option: IFilterDropdownOption) => JSX.Element,
	onChangeFilter: (item: IFilterDropdownOption) => void,
	onChangeFilters: (item: IFilterDropdownOption) => void
] => {
	const { displayName, fieldName, icons, multivalue, values } = props;
	const { onSelectFilters } = props;
	const { filters, searchResult, removeFilter } = useSearch();
	const loggerService = useLoggerService();

	// Helper function to check if a default category should be set
	const getDefaultFilterDropdownOptions = () => {
		// If a category is selected and there are no results,
		// then the selected category should be set as selected dropdown option
		if (fieldName === Globals.Filters.Category) {
			const categoryFilter = filters.find((f) => f.fieldName === fieldName);
			if (categoryFilter && searchResult?.totalCount === 0) {
				return [
					{
						text: categoryFilter.text,
						key: categoryFilter.key,
						value: categoryFilter.value,
						count: -1
					}
				];
			}
		}
		return undefined;
	};

	// Helper function to check if the filter should be disabled
	const getIsDisabled = (): boolean => {
		// If no category is selected and there are no results then disable category and filetype control
		if (fieldName === Globals.Filters.Category || fieldName === Globals.Filters.Filetype) {
			const categoryFilter = filters.find((f) => f.fieldName === Globals.Filters.Category);
			if (!categoryFilter && searchResult?.totalCount === 0) {
				return true;
			}
		}
		return false;
	};

	// Helper function to track filter selection
	const trackFilterSelection = (type: string, selectedItem?: IFilterDropdownOption) => {
		switch (type) {
			case Globals.Filters.Category:
				loggerService?.trackEvent(Globals.Telemetry.Events.ON_CATEGORY_FILTER_SELECT, {
					payload: selectedItem?.text ?? ""
				});
				break;
			case Globals.Filters.Filetype:
				const selectedItems = selectedOptions.map((o) => o.text);
				loggerService?.trackEvent(Globals.Telemetry.Events.ON_FILETYPE_FILTER_SELECT, {
					payload: JSON.stringify(selectedItems)
				});
				break;
			default:
				break;
		}
	};

	// Set selected filters
	const [selectedOptions, setSelectedOptions] = React.useState<IFilterDropdownOption[]>(() => {
		// In case of category filter return the selected category if any and no results
		const defaultOptions = getDefaultFilterDropdownOptions();
		if (!!defaultOptions) {
			return defaultOptions;
		}

		return filters
			.filter((filter: ISearchFilter) => filter.fieldName === fieldName)
			.map((filter: ISearchFilter) => ({
				text: filter.text,
				key: filter.key,
				value: filter.value,
				count: filter.count
			}));
	});

	// Map values to dropdown items
	const defaultFilterOptions = getDefaultFilterDropdownOptions();
	const dropdownOptions =
		defaultFilterOptions ??
		values.map((filterValue: IFilterValue) => {
			return {
				text: filterValue.text,
				key: filterValue.key,
				value: filterValue.value,
				count: filterValue.count
			};
		});

	// Add apply button if multivalue
	if (multivalue) {
		const divider = {
			text: "-",
			key: "divider",
			value: "divider",
			count: 0,
			itemType: DropdownMenuItemType.Divider
		};
		dropdownOptions.push(divider);
		dropdownOptions.push({
			text: "deselect_button",
			key: "deselect_button",
			value: "deselect_button",
			count: 0
		});
		dropdownOptions.push({
			text: "apply_button",
			key: "apply_button",
			value: "apply_button",
			count: 0
		});
	}

	// Render dropdown options
	const onRenderDropdownOption = (option: IFilterDropdownOption): JSX.Element => {
		if (option?.key === "apply_button") {
			return <PrimaryButton text="Apply" onClick={() => onApplyFilters()} />;
		} else if (option?.key === "deselect_button") {
			return (
				<DefaultButton
					text="Deselect"
					onClick={() => onClearFilters()}
					iconProps={{ iconName: "ChromeClose" }}
					disabled={selectedOptions.length === 0}
				/>
			);
		} else {
			const countLabel = option.count === -1 ? "" : `(${option.count})`;
			const text = `${option?.text} ${countLabel}`;

			if (!props.multivalue && option?.key === selectedOptions[0]?.key) {
				return (
					<div>
						<span>{text}</span>
						<span className={classNames.dropdownOptionCloseIcon}>
							<Icon
								onClick={(ev) => {
									ev.stopPropagation();
									removeFilter(props.fieldName, option.key.toString());
								}}
								iconName={"ChromeClose"}
							/>
						</span>
					</div>
				);
			} else {
				if (icons?.length > 0 && icons?.some((i) => i.key === option.key)) {
					const iconName = icons?.find((i) => i.key === option.key)?.iconName;
					return (
						<div className={classNames.dropdownOption}>
							<Icon iconName={iconName} />
							<span>{text}</span>
						</div>
					);
				} else {
					return (
						<div className={classNames.dropdownOption}>
							<span>{text}</span>
						</div>
					);
				}
			}
		}
	};

	// Add single value filter
	const onChangeFilter = (item: IFilterDropdownOption): void => {
		if (item) {
			// Track event for category filter
			if (fieldName === Globals.Filters.Category) {
				trackFilterSelection(Globals.Filters.Category, item);
			}
			onSelectFilters(fieldName, [
				{
					fieldName: fieldName,
					displayName: displayName,
					text: item.text,
					key: item.key as string,
					value: item.value,
					count: item.count
				}
			]);
		}
	};

	// Change multivalue filter
	const onChangeFilters = (item: IFilterDropdownOption): void => {
		if (item) {
			setSelectedOptions(
				item.selected ? [...selectedOptions, item] : selectedOptions.filter((o) => o.key !== item.key)
			);
		}
	};

	// Apply filters
	const onApplyFilters = () => {
		if (!!selectedOptions) {
			// Track event for filetype filter
			if (fieldName === Globals.Filters.Filetype) {
				trackFilterSelection(Globals.Filters.Filetype);
			}
			const filtersToAdd = selectedOptions.map((item: IFilterDropdownOption) => ({
				fieldName: fieldName,
				displayName: displayName,
				text: item.text,
				key: item.key as string,
				value: item.value,
				count: item.count
			}));
			onSelectFilters(fieldName, filtersToAdd);
		}
	};

	// Clear filters
	const onClearFilters = () => {
		onSelectFilters(fieldName, []);
	};

	return [dropdownOptions, selectedOptions, getIsDisabled(), onRenderDropdownOption, onChangeFilter, onChangeFilters];
};
