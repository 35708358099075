import { newGuid } from "@microsoft/applicationinsights-core-js";
import Globals from "../../Globals";
import { ISearchFilter } from "../../models/SearchFilter";
import { ISearchState, SearchAction } from "./search.types";

export const searchReducer = (state: ISearchState, action: SearchAction): ISearchState => {
	switch (action.type) {
		case "BEGIN_SEARCH":
			// Add the query id to the session store for each new query
			window.sessionStorage.setItem(Globals.CacheKeys.QUERY_ID, newGuid());

			// Set the state
			const searchResultStart = state.searchResult;
			if (!state.keepResults) {
				searchResultStart.items = [];
				searchResultStart.filters = [];
				searchResultStart.totalCount = 0;
				searchResultStart.moreResultsAvailable = false;
				searchResultStart.currentPage = 0;
			}
			return { ...state, isSearching: true, searchResult: searchResultStart, error: undefined };

		case "COMPLETE_SEARCH":
			if (state.keepResults) {
				const searchResult = state.searchResult;
				searchResult.items = searchResult?.items.concat(action.payload.items);
				searchResult.moreResultsAvailable = action.payload?.moreResultsAvailable;
				searchResult.totalCount = action.payload?.totalCount;
				searchResult.filters = action.payload?.filters;
				return { ...state, isSearching: false, searchResult: searchResult };
			}

			// Query modification result
			const isQueryModificationResult = action.payload?.isQueryModificationResult ?? false;
			const queryModificationResult = action.payload?.queryModification ?? { enabled: false };
			const allSuggestions = state.suggestionResponse?.suggestions?.concat(state.suggestionResponse?.noResultsSuggestions ?? []) ?? [];

			// Query modification if:
			//   - no results are found 
			//   - query modification is enabled
			//   - query modification is not yet requested
			if (action.payload?.totalCount == 0 && queryModificationResult.enabled && isQueryModificationResult === false) {
				// Query modification not yet possible because suggestions are not yet available
				if (state.isFetchingSuggestions) {
					return {
						...state,
						isSearching: false,
						searchResult: action.payload,
						queryModification: {
							queryModificationOriginalQueryText: state.queryText,
							isQueryModificationRequest: true,
							executeQueryModificationOnSuggestionComplete: true
						}
					};
				}
				// Query modification: Retry with first suggestion if available
				else if (allSuggestions.length > 0) {
					return {
						...state,
						isSearching: false,
						searchResult: action.payload,
						queryText: allSuggestions[0].suggestion,
						queryModification: {
							queryModificationOriginalQueryText: state.queryText,
							isQueryModificationRequest: true,
							executeQueryModificationOnSuggestionComplete: false
						}
					};
				}
				// Query modification: Retry with modified query text if available
				else if (!!queryModificationResult.modifiedQueryText) {
					return {
						...state,
						isSearching: false,
						searchResult: action.payload,
						queryText: queryModificationResult.modifiedQueryText,
						queryModification: {
							queryModificationOriginalQueryText: state.queryText,
							isQueryModificationRequest: true,
							executeQueryModificationOnSuggestionComplete: false
						}
					};
				}
			}

			// No query modification available
			return {
				...state,
				isSearching: false,
				searchResult: action.payload,
				queryModification: {
					queryModificationOriginalQueryText: state.queryText,
					isQueryModificationRequest: false,
					executeQueryModificationOnSuggestionComplete: false
				},
			};
		case "ERROR_SEARCH":
			return { ...state, isSearching: false, keepResults: false, page: 0, error: action.payload };
		case "SET_QUERY_TEXT":
			const filters = [...state.filters];
			const result = filters.filter((obj) => {
				return obj.fieldName === Globals.Filters.Category || obj.fieldName === Globals.Filters.ProfileFilter;
			});
			return {
				...state,
				hasSubmittedQuery: true,
				queryText: action.payload.newQuery,
				includeSuggestions: action.payload.includeSuggestions,
				keepResults: false,
				page: 0,
				filters: result
			};
		case "SET_SCOPE":
			return { ...state, scope: action.payload };
		case "SET_PAGE":
			return { ...state, page: action.payload, keepResults: true };
		case "SUGGEST":
			return { ...state, suggestionResponse: undefined, queryText: action.payload };
		case "RESET":
			return {
				...state,
				suggestionResponse: undefined,
				queryText: "",
				filters: [Globals.DefaultProfileFilter.filter],
				page: 0,
				keepResults: false,
				hasSubmittedQuery: false,
				addDefaultProfileFilter: true
			};
		case "BEGIN_SUGGEST":
			return { ...state, isFetchingSuggestions: true };
		case "COMPLETE_SUGGEST":
			const allCompletedSuggestions = action.payload?.suggestions?.concat(action.payload?.noResultsSuggestions ?? []) ?? [];
			if (
				state.queryModification.executeQueryModificationOnSuggestionComplete &&
				allCompletedSuggestions.length > 0
			) {

				return {
					...state,
					queryText: allCompletedSuggestions[0].suggestion,
					queryModification: {
						queryModificationOriginalQueryText: state.queryText,
						isQueryModificationRequest: true,
						executeQueryModificationOnSuggestionComplete: false
					}
				};
			}

			return { ...state, suggestionResponse: action.payload, isFetchingSuggestions: false };
		case "CLEAR_SUGGEST":
			return { ...state, suggestionResponse: undefined };
		case "ADD_FILTERS":

			// Build new filter array
			const newFilterArrayAdd = [...state.filters.filter(f => f.fieldName !== action.payload.fieldName)];
			action.payload.newFilters.forEach((filter: ISearchFilter) => {
				if (!newFilterArrayAdd.some((obj) => obj.key === filter.key)) {
					newFilterArrayAdd.push(filter);
				}
			});

			// Add default profile filter if category is selected and not updated
			if (action.payload.fieldName === Globals.Filters.Category && state.addDefaultProfileFilter) {
				// Only add if not already added
				if (!state.filters.some(f => f.key === Globals.DefaultProfileFilter.filter.key)) {
					const categoryValue = action.payload.newFilters[0]?.value?.toLowerCase();
					if (Globals.DefaultProfileFilter.enabledCategories.some(c => c.toLowerCase() === categoryValue)) {
						newFilterArrayAdd.push(Globals.DefaultProfileFilter.filter);
					}
				}
			}

			// If the profile filter is added, update the addDefaultProfileFilter state
			const addDefaultProfileFilterAdd = action.payload.fieldName === Globals.Filters.ProfileFilter
				? true
				: state.addDefaultProfileFilter;

			// Set state
			return {
				...state,
				keepResults: false,
				page: 0,
				filters: newFilterArrayAdd,
				addDefaultProfileFilter: addDefaultProfileFilterAdd
			};
		case "REMOVE_FILTER":
			if (action.payload.fieldName === Globals.Filters.Category) {
				// Category is removed, keep profile filter if it is not removed
				if (state.addDefaultProfileFilter) {
					return { ...state, keepResults: false, page: 0, filters: [Globals.DefaultProfileFilter.filter] };
				} else {
					return { ...state, keepResults: false, page: 0, filters: [] };
				}
			} else {
				// Filter out the new filter array
				const newFilterArrayRemove = state.filters.filter((obj) => {
					return obj.key !== action.payload.key;
				});

				// If the profile filter is removed, update the addDefaultProfileFilter state
				const addDefaultProfileFilterRemove = action.payload.fieldName === Globals.Filters.ProfileFilter
					? false
					: state.addDefaultProfileFilter;

				// Set state
				return {
					...state,
					keepResults: false,
					page: 0,
					filters: newFilterArrayRemove,
					addDefaultProfileFilter: addDefaultProfileFilterRemove
				};
			}
		case "SET_INIT_RESULTS":
			return {
				...state,
				hasSubmittedQuery: true,
				queryText: action.payload.query,
				page: action.payload.pageNr,
				filters: action.payload.filters
			};
		default:
			return state;
	}
};
