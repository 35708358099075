import { classNamesFunction } from "office-ui-fabric-react/lib/Utilities";
import * as React from "react";
import { useTheme } from "../../models/contexts/ThemeContext";
import { ILoadingShimmerProps, ILoadingShimmerStyleProps, ILoadingShimmerStyles } from "./LoadingShimmer.types";
import { ShimmerItem } from "./ShimmerItem";

const getClassNames = classNamesFunction<ILoadingShimmerStyleProps, ILoadingShimmerStyles>();

export const LoadingShimmerBase: React.FC<ILoadingShimmerProps> = (props) => {
	const [shimmerItems, setShimmerItems] = React.useState<JSX.Element[]>([<ShimmerItem key={0} />]);

	React.useEffect(() => {
		const interval = setInterval(() => {
			if (shimmerItems.length < 10) {
				setShimmerItems((arr) => {
					if (arr.length < 7) {
						return [...arr, <ShimmerItem key={arr.length} />];
					} else {
						clearInterval(interval);
						return arr;
					}
				});
			}
		}, 500);
		return () => clearInterval(interval);
	}, []);

	// Styling initialization
	const { className, styles } = props;
	const theme = useTheme();
	const classNames = getClassNames(styles, { className, theme: theme! });

	return <div className={classNames.root}>{shimmerItems}</div>;
};
