import Globals from "../../Globals";
import { ISearchResultItemStyleProps, ISearchResultItemStyles } from "./SearchResultItem.types";

const GlobalClassNames = {
	root: "mse-SearchResultItem",
	persona: "mse-SearchResultItem-persona",
	icon: "mse-SearchResultItem-icon",
	resultType: "mse-SearchResultItem-resultType",
	resultTypeBadge: "mse-SearchResultItem-resultTypeBadge",
	resultTypeByLine: "mse-SearchResultItem-resultTypeByLine",
	resultTypeDate: "mse-SearchResultItem-resultTypeDate",
	resultTypeOpenBrowserIcon: "mse-SearchResultItem-resultTypeOpenBrowserIcon",
	worldIcon: "mse-SearchResultItem-worldIcon",
	title: "mse-SearchResultItem-title",
	titleText: "mse-searchResultItem-titleText",
	description: "mse-SearchResultItem-description",
	highlight: "mse-SearchResultItem-highlight",
	seperator: "mse-SearchResultItem-seperator",
	image: "mse-SearchResultItem-image",

};

export const getStyles = (props: ISearchResultItemStyleProps): ISearchResultItemStyles => {
	const { className, theme } = props;
	const classNames = GlobalClassNames;

	return {
		root: [
			classNames.root,
			{
				textDecoration: "none",
				display: "grid",
				borderBottom: "solid 1px #DBDBDB",
				marginBottom: "5px",
				gap: "0px 20px", 
				paddingBottom: "5px",
				gridTemplateColumns: "30px 1fr 128px",
				gridTemplateRows: "30px 30px 1fr", 
				gridTemplateAreas: 
					`"${classNames.icon} ${classNames.resultType} ${classNames.image}" 
					"${classNames.icon} ${classNames.title} ${classNames.image}" 
					"${classNames.icon} ${classNames.description} ${classNames.image}"`,
					[Globals.Styling.MediaQueries.MobileSmall]: {
					gap: "0px 5px", 
					gridTemplateColumns: "30px 1fr",
					gridTemplateRows: "30px 30px 1fr", 
					gridTemplateAreas: 
						`"${classNames.icon} ${classNames.resultType}" 
						"${classNames.icon} ${classNames.title}" 
						"${classNames.icon} ${classNames.description}"`
					}
			},
			className
		],

		icon: [classNames.icon, {
			width: "30px",
			height: "30px",
			gridArea: classNames.icon,
			fill: theme.semanticColors.inputIcon
		}],
		
		resultType: [classNames.resultType, {
			display: "flex",
			alignItems: "center",
			gap: "10px",
			gridArea: classNames.resultType
		}],

		title: [classNames.title, {
			fontFamily: theme.fonts.medium.fontFamily,
			fontStyle: "normal",
			fontWeight: "400",
			fontSize: "20px",
			lineHeight: "30px",
			color: theme.semanticColors.bodySubtext,
			gridArea: classNames.title,
			textOverflow: "ellipsis",
			overflow: "hidden",
			whiteSpace: "nowrap"
		}],
		titleText: [classNames.titleText, {
			cursor: "pointer",
			textOverflow: "ellipsis",
			overflow: "hidden",
			whiteSpace: "nowrap"
		}],

		description: [classNames.description, {
			fontFamily: theme.fonts.medium.fontFamily,
			fontStyle: "normal",
			fontWeight: "400",
			fontSize: "14px",
			lineHeight: "20px",
			color: theme.semanticColors.bodySubtext,
			gridArea: classNames.description,
			overflow: "hidden",
			textOverflow: "ellipsis",
			display: "-webkit-box",
			cursor: "pointer",
			"-webkit-line-clamp": "2",
			"-webkit-box-orient": "vertical",
			" *": {
				margin: "0px !important",
				fontStyle: "normal !important",
				fontWeight: "400 !important",
				fontSize: "14px !important",
				lineHeight: "20px !important"
			}
		}],

		image: [classNames.image, {
			width: "128px",
    	height: "98px",
			marginBottom: "6px",
			gridArea: classNames.image,
			cursor: "pointer",
			[Globals.Styling.MediaQueries.MobileSmall]: {
				display: "none"
			}
		}],

		highlight: [classNames.highlight, {
			backgroundColor: "rgba(108, 194, 200, 0.22)"
		}],
		resultTypeBadge: [classNames.resultTypeBadge, {
			fontFamily: theme.fonts.medium.fontFamily,
			fontStyle: "normal",
			fontWeight: "700",
			fontSize: "12px",
			lineHeight: "20px",
			textAlign: "center",
			color: "#fff",
			background: "#A4C5E4",
			borderRadius: "3px",
			padding: "2px 6px 2px 5px",
			height: "20px",
		}],
		resultTypeByLine: [classNames.resultTypeByLine, {
			display: "flex",
			flex: "1",
			justifyContent: "space-between"
		}],
		resultTypeDate: [classNames.resultTypeDate, {
			color: theme.semanticColors.bodyText,
			fontWeight: "400",
			fontSize: "12px",
		}],
		resultTypeOpenBrowserIcon: [classNames.resultTypeOpenBrowserIcon, {
			color: "white"
		}],
		worldIcon: [classNames.worldIcon, {
			color: theme.semanticColors.bodySubtext,
			width: "12px",
			height: "12px"
		}],
		persona: [classNames.persona, {}]
	};
};