import { Checkbox, Icon, TooltipHost } from "office-ui-fabric-react";
import { classNamesFunction } from "office-ui-fabric-react/lib/Utilities";
import * as React from "react";
import { useTheme } from "../../../models/contexts/ThemeContext";
import { checkboxStyles } from "./ProfileFilter.styles";
import { IProfileFilterProps, IProfileFilterStyleProps, IProfileFilterStyles } from "./ProfileFilter.types";
import { useProfileFilter } from "./UseProfileFilter";

const getClassNames = classNamesFunction<IProfileFilterStyleProps, IProfileFilterStyles>();

export const ProfileFilterBase: React.FC<IProfileFilterProps> = (props) => {
	const {} = props;
	const [checked, onCheckboxChange] = useProfileFilter(props);

	// Styling initialization
	const { className, styles } = props;
	const theme = useTheme();
	const classNames = getClassNames(styles, { className, theme: theme! });

	return (
		<div className={classNames.root}>
			<span className={classNames.label}>{props.displayName}</span>
			<div className={classNames.checkboxContainer}>
				<Checkbox styles={checkboxStyles} checked={checked} onChange={onCheckboxChange} />
				<span className={classNames.checkboxText}>Personalized results</span>
				<TooltipHost
					content="Turn off to see search results from all locations, buildings and organizational units."
					id={"search-personalization-tooltip"}
				>
					<Icon iconName={"Info"} className={classNames.infoIcon} />
				</TooltipHost>
			</div>
		</div>
	);
};
