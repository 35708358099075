import { classNamesFunction } from "office-ui-fabric-react/lib/Utilities";
import * as React from "react";
import Globals from "../../Globals";
import { ErrorBoundary } from "../../components/ErrorBoundry/ErrorBoundary";
import { SearchContainer } from "../../components/SearchContainer";
import { SearchResultsList } from "../../components/SearchResultsList";
import { useTheme, useViewType } from "../../models";
import { ISearchFilter } from "../../models/SearchFilter";
import { ViewType } from "../../models/ViewType";
import { useSearch, useSearchExecution, useSuggestions } from "../../reducers";
import { useLoggerService } from "../../services/logger";
import Symbols from "../../svg-symbols/Symbols";
import { IResultsViewProps, IResultsViewStyleProps, IResultsViewStyles } from "./ResultsView.types";

const getClassNames = classNamesFunction<IResultsViewStyleProps, IResultsViewStyles>();

export const ResultsViewBase: React.FC<IResultsViewProps> = (props) => {
	const { disablePersonalSearchHistory } = props;

	const [, setViewType] = useViewType();
	const { initResults, reset } = useSearch();
	const loggerService = useLoggerService();

	const onBackClick = () => {
		loggerService?.trackEvent(Globals.Telemetry.Events.ON_BACK_TO_HOME_CLICK);
		reset();
		window.history.replaceState(null, "", "/");
		setViewType(ViewType.landing);
	};

	// Ensure search query is executed when the search input changes
	useSearchExecution();

	// Enable Autocomplete
	useSuggestions();

	// Set the search input based on the query string
	React.useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);

		const query = urlParams.get("query") ?? "*";
		const filters = JSON.parse(urlParams.get("filter") ?? "[]") as ISearchFilter[];
		const page = urlParams.get("page") ?? 0;
		initResults(query, +page, filters);
	}, []);

	// Styling initialization
	const { className, styles } = props;
	const theme = useTheme();
	const classNames = getClassNames(styles, { className, theme: theme! });

	return (
		<div className={classNames.root}>
			<SearchContainer
				title="Search corporate imec information"
				big={false}
				disablePersonalSearchHistory={disablePersonalSearchHistory}
			/>
			<div className={classNames.backButtonContainer}>
				<a className={classNames.backButton} onClick={onBackClick}>
					<svg className={classNames.backImage}>
						<use xlinkHref={Symbols.Icons.IconBack} />
					</svg>
					Back
				</a>
			</div>
			<ErrorBoundary>
				<SearchResultsList />
			</ErrorBoundary>
		</div>
	);
};
