import { styled } from "office-ui-fabric-react/lib/Utilities";
import { ProfileFilterBase } from "./ProfileFilter.base";
import { getStyles } from "./ProfileFilter.styles";
import { IProfileFilterProps, IProfileFilterStyleProps, IProfileFilterStyles } from "./ProfileFilter.types";

export const ProfileFilter: React.FC<IProfileFilterProps> = styled<
	IProfileFilterProps,
	IProfileFilterStyleProps,
	IProfileFilterStyles
>(ProfileFilterBase, getStyles);
