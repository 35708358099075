import { styled } from "office-ui-fabric-react/lib/Utilities";
import { ImecModalBase } from "./ImecModal.base";
import { getStyles } from "./ImecModal.styles";
import { IImecModalProps, IImecModalStyleProps, IImecModalStyles } from "./ImecModal.types";

export const ImecModal: React.FC<IImecModalProps> = styled<
	IImecModalProps,
	IImecModalStyleProps,
	IImecModalStyles
>(ImecModalBase, getStyles);
