import Globals from "../../Globals";
import { ISearchResultsListStyleProps, ISearchResultsListStyles } from "./SearchResultsList.types";

const GlobalClassNames = {
	root: "mse-SearchResultsList",
	resultsRow: "mse-SearchResultsList-resultsRow",
	resultsRowFeedback: "mse-SearchResultsList-resultsRowFeedback",
	resultCount: "mse-SearchResultsList-resultCount",
	seperator: "mse-SearchResultsList-seperator",
	loading: "mse-SearchResultsList-loading"
};

export const getStyles = (props: ISearchResultsListStyleProps): ISearchResultsListStyles => {
	const { className, theme } = props;
	const classNames = GlobalClassNames;

	return {
		root: [classNames.root, {
			backgroundColor: theme.semanticColors.bodyBackground,
			padding: "10px 8%",
			display: "flex",
			flexDirection: "column",
			flex: "1",
			[Globals.Styling.MediaQueries.MobileSmall]: {
				padding: "10px 15px"
			}
		}, className],
		resultsRow: [classNames.resultsRow, {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			flexWrap: "wrap",
			alignItems: "center"
		}],
		resultsRowFeedback: [classNames.resultsRowFeedback, {
			display: "flex",
			lineHeight: "32px",
			alignItems: "center",
			color: theme.semanticColors.bodySubtext,
			">i": {
				marginLeft: "5px",
				height: "25px",
				cursor: "pointer"
			}
		}],
		resultCount: [classNames.resultCount, {
			fontFamily: theme.fonts.medium.fontFamily,
			fontStyle: "normal",
			fontWeight: "400",
			fontSize: "15px",
			lineHeight: "32px",
			color: theme.semanticColors.bodySubtext,
		}],
		seperator: [classNames.seperator, {
			height: "1px",
			background: "#C4C4C4",
			borderStyle: "hidden",
		}],
		loading: [classNames.loading, {
			fontFamily: theme.fonts.medium.fontFamily,
			fontStyle: "normal",
			fontWeight: "400",
			fontSize: "15px",
			lineHeight: "32px",
		}]
	};
};
