import { styled } from "office-ui-fabric-react/lib/Utilities";
import { ImecButtonBase } from "./ImecButton.base";
import { getStyles } from "./ImecButton.styles";
import { IImecButtonProps, IImecButtonStyleProps, IImecButtonStyles } from "./ImecButton.types";

export const ImecButton: React.FC<IImecButtonProps> = styled<
	IImecButtonProps,
	IImecButtonStyleProps,
	IImecButtonStyles
>(ImecButtonBase, getStyles);
