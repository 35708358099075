import { classNamesFunction } from "office-ui-fabric-react/lib/Utilities";
import * as React from "react";
import Globals from "../../Globals";
import { useTheme } from "../../models/contexts/ThemeContext";
import { ISearchFilter } from "../../models/SearchFilter";
import { useSearch } from "../../reducers";
import Symbols from "../../svg-symbols/Symbols";
import { SearchFilter } from "../SearchFilter/SearchFilter";
import {
	ISearchFiltersContainerProps,
	ISearchFiltersContainerStyleProps,
	ISearchFiltersContainerStyles
} from "./SearchFiltersContainer.types";

const getClassNames = classNamesFunction<ISearchFiltersContainerStyleProps, ISearchFiltersContainerStyles>();

export const SearchFiltersContainerBase: React.FC<ISearchFiltersContainerProps> = (props) => {
	const { searchResult, isSearching, filters, addFilters, removeFilter } = useSearch();

	// Styling initialization
	const { className, styles } = props;
	const theme = useTheme();
	const classNames = getClassNames(styles, { className, theme: theme! });

	const onSelectFilters = (fieldName: string, selectedFilters: ISearchFilter[]) => {
		addFilters(fieldName, selectedFilters);
	};

	const onRemoveFilterClick = (fieldName: string, key: string) => {
		removeFilter(fieldName, key);
	};

	const filtersToRender = filters?.filter((f) => f.fieldName !== Globals.Filters.ProfileFilter);
	return (
		<div className={classNames.searchFiltersContainer}>
			{!isSearching && searchResult && searchResult.filters && (
				<>
					<div className={classNames.searchFilters}>
						{searchResult.filters.map((filter) => (
							<div key={filter.fieldName} className={classNames.searchFilter}>
								<SearchFilter
									fieldName={filter.fieldName}
									displayName={filter.displayName}
									icons={filter.icons}
									values={filter.items}
									multivalue={filter.multivalue}
									filterType={filter.filterType}
									onSelectFilters={onSelectFilters}
								/>
							</div>
						))}
					</div>
					{filtersToRender && filtersToRender.length > 0 && (
						<div className={classNames.selectedFiltersContainer}>
							<span className={classNames.selectedFiltersContainerLabel}>{"Applied filters:"}</span>
							{filtersToRender.map((filter) => (
								<div key={filter.key} className={classNames.selectedFilter}>
									<span>
										{filter.displayName}: {filter.text}
									</span>
									<a
										className={classNames.removeFilterButton}
										onClick={() => onRemoveFilterClick(filter.fieldName, filter.key)}
									>
										<svg className={classNames.clearIcon}>
											<use xlinkHref={Symbols.Icons.IconClear} />
										</svg>
									</a>
								</div>
							))}
						</div>
					)}
				</>
			)}
		</div>
	);
};
