import { styled } from "office-ui-fabric-react/lib/Utilities";
import { DateFilterBase } from "./DateFilter.base";
import { getStyles } from "./DateFilter.styles";
import { IDateFilterProps, IDateFilterStyleProps, IDateFilterStyles } from "./DateFilter.types";

export const DateFilter: React.FC<IDateFilterProps> = styled<
	IDateFilterProps,
	IDateFilterStyleProps,
	IDateFilterStyles
>(DateFilterBase, getStyles);
