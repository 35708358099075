import * as React from "react";
import { useSearchService } from "../../models";
import { ISearchFilter } from "../../models/SearchFilter";
import { SearchScope } from "../../models/SearchScope";
import { ISearchQueryInput } from "../../services";
import { useProfile } from "../../services/profile/ProfileContext";
import { StringUtility } from "../../utils/StringUtility";
import { SearchContext } from "./search.context";
import { UseSearchReturnType } from "./search.types";

/**
 * Hook to execute the search query.
 * This hook includes an effect which executes the search query when relevant state is updated.
 * Important! Make sure to include this in only 1 mounted component at a time.
 * If not, multiple queries will be started simultaneously.
 */
export const useSearchExecution = (): (() => void) => {
	const { state, dispatch } = React.useContext(SearchContext);
	const { hasSubmittedQuery, queryText, scope, filters, page, isSearching, queryModification } = state;

	const searchService = useSearchService();
	const profileProperties = useProfile();

	const executeSearch = React.useCallback(() => {
		(async () => {
			if (!searchService) {
				throw new Error("There is no search service available to work with.");
			}

			// initialize state change
			dispatch({ type: "BEGIN_SEARCH" });

			// update URL
			const urlQueryText = queryText ? encodeURIComponent(queryText) : "*";
			window.history.replaceState(null, "", `?query=${urlQueryText}&page=${page}&filter=${encodeURIComponent(JSON.stringify(filters))}`);

			// build search query input
			const searchInput: ISearchQueryInput = {
				queryText,
				filters,
				page,
				profileProperties,
				isQueryModificationRequest: queryModification.isQueryModificationRequest,
				queryModificationOriginalQueryText: queryModification.queryModificationOriginalQueryText
			};

			try {
				// execute search query
				const result = await searchService.executeQuery(searchInput);

				// finalize state
				dispatch({ type: "COMPLETE_SEARCH", payload: result });
			} catch (error) {
				dispatch({ type: "ERROR_SEARCH", payload: error });
			}
		})();
	}, [queryText, scope, filters, page]);

	React.useEffect(() => {
		if (hasSubmittedQuery && !isSearching) {
			executeSearch();
		}
	}, [queryText, scope, filters, page]);

	return executeSearch;
};

export const useSuggestions = (): (() => void) => {
	const { state, dispatch } = React.useContext(SearchContext);
	const { queryText, includeSuggestions } = state;

	const searchService = useSearchService();

	const suggest = React.useCallback(() => {
		(async () => {
			if (!searchService) {
				throw new Error("There is no search service available to work with.");
			}

			dispatch({ type: "BEGIN_SUGGEST" });

			// execute suggest query
			const result = await searchService.suggest(queryText);

			// finalize state
			dispatch({ type: "COMPLETE_SUGGEST", payload: result });

		})();
	}, [queryText]);

	React.useEffect(() => {
		if (queryText && includeSuggestions) {
			suggest();
		}
	}, [queryText]);

	return suggest;
};

/**
 * Hook to access and update search context.
 */
export const useSearch = (): UseSearchReturnType => {
	const { state, dispatch } = React.useContext(SearchContext);
	const { suggestionResponse, queryText, searchResult, scope, isSearching, filters, page, error, queryModification } = state;

	// change the current search scope
	const setScope = (newScope: SearchScope) => {
		dispatch({ type: "SET_SCOPE", payload: newScope });
	};

	const addFilters = (fieldName: string, newFilters: ISearchFilter[]) => {
		dispatch({ type: "ADD_FILTERS", payload: { fieldName, newFilters } });
	};

	const removeFilter = (fieldName: string, key: string) => {
		dispatch({ type: "REMOVE_FILTER", payload: { fieldName, key } });
	};

	const setPage = (pageNr: number) => {
		dispatch({ type: "SET_PAGE", payload: pageNr });
	};

	const suggest = (newQuery: string) => {
		dispatch({ type: "SUGGEST", payload: newQuery });
	};

	const clearSuggest = () => {
		dispatch({ type: "CLEAR_SUGGEST" });
	};

	const reset = () => {
		dispatch({ type: "RESET" });
	};

	// submit a new search query
	const submitQuery = (newQuery: string, includeSuggestions: boolean) => {
		dispatch({ type: "SET_QUERY_TEXT", payload: { newQuery, includeSuggestions } });
	};

	const initResults = (query: string, pageNr: number, newFilters: ISearchFilter[]) => {
		dispatch({ type: "SET_INIT_RESULTS", payload: { query, pageNr, filters: newFilters } });
	}

	return {
		currentQuery: queryText,
		currentQueryWords: StringUtility.getWordsFromString(queryText),
		scope,
		searchResult,
		queryModification,
		filters,
		isSearching,
		page,
		suggestionResponse,
		error,
		setScope,
		addFilters,
		removeFilter,
		setPage,
		suggest,
		clearSuggest,
		submitQuery,
		initResults,
		reset
	};
};
