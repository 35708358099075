import { IImecButtonStyleProps, IImecButtonStyles } from "./ImecButton.types";

const GlobalClassNames = {
	root: "mse-ImecButton"
};

export const getStyles = (props: IImecButtonStyleProps): IImecButtonStyles => {
	const { className, theme } = props;
	const classNames = GlobalClassNames;

	return {
		root: [classNames.root, {
			background: "linear-gradient(112.32deg, #3F98BD 7.52%, #52BDC2 90.36%)",
			boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
			borderRadius: "2px",
			textDecoration: "none",
			color: "white",
			fontFamily: theme.fonts.medium.fontFamily,
			fontStyle: "normal",
			fontWeight: "600",
			padding: "5px 15px",
			display: "inline-block",
			fontSize: "14px",
    	lineHeight: "20px",
			":focus-visible": {
				outline: "none"
			}
		}, className]
	};
};
