import { styled } from "office-ui-fabric-react/lib/Utilities";
import { AuthenticationModalBase } from "./AuthenticationModal.base";
import { getStyles } from "./AuthenticationModal.styles";
import { IAuthenticationModalProps, IAuthenticationModalStyleProps, IAuthenticationModalStyles } from "./AuthenticationModal.types";

export const AuthenticationModal: React.FC<IAuthenticationModalProps> = styled<
	IAuthenticationModalProps,
	IAuthenticationModalStyleProps,
	IAuthenticationModalStyles
>(AuthenticationModalBase, getStyles);
