import { IButtonProps } from "office-ui-fabric-react";
import { IIconProps } from "office-ui-fabric-react/lib/Icon";
import { classNamesFunction } from "office-ui-fabric-react/lib/Utilities";
import * as React from "react";
import { useSearchService } from "../../models/contexts/SearchServiceContext";
import { useTheme } from "../../models/contexts/ThemeContext";
import { AutocompleteSearchBox } from "../AutoCompleteSearchBox";
import { IHistoryProps, ISuggestionItem } from "../AutoCompleteSearchBox/AutoCompleteSearchBox.types";
import { ISearchBarProps, ISearchBarStyleProps, ISearchBarStyles } from "./SearchBar.types";

const getClassNames = classNamesFunction<ISearchBarStyleProps, ISearchBarStyles>();

export const SearchBarBase: React.FC<ISearchBarProps> = (props) => {
	const textInput = React.useRef<HTMLDivElement>(null);
	const { value, disablePersonalSearchHistory, onChange: onChangeProp, onSearch: onSearchProp, onClear: onClearProp } = props;
	const searchIcon: IIconProps = { iconName: "ImecSearch" };
	const clearIcon: IIconProps = { iconName: "ImecClear" };
	const historyIcon: IIconProps = { iconName: "ImecHistory" };
	const deleteHistoryIcon: IIconProps = { iconName: "ImecDelete" };
	const clearButton: IButtonProps = { iconProps: clearIcon };
	const searchService = useSearchService();
	const queryThreshold = 1;
	const [inProgress, setProgress] = React.useState(false);
	const [dynamicSuggestions, setDynamicSuggestions] = React.useState<string[] | ISuggestionItem[]>();

	// Styling initialization
	const { className, styles } = props;
	const theme = useTheme();
	const classNames = getClassNames(styles, { className, theme: theme! });

	const handleSearch = (newValue: string) => {
		if (!!onSearchProp && newValue && newValue.length > 0) {
			onSearchProp(newValue, true);
		}
	};
	const handleClear = (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
		if (!!onClearProp) {
			onClearProp();
		}
	};

	const onChange = (event?: React.ChangeEvent<HTMLInputElement> | undefined, newText?: string) => {
		if (!!onChangeProp) {
			onChangeProp(newText);
		}

		if (!newText || newText.trim().length < queryThreshold) {
			setDynamicSuggestions(undefined);
		} else {
			setProgress(true);
			searchService?.autoComplete(newText).then((result) => {
				setDynamicSuggestions(result);
				setProgress(false);
			});
		}
	};

	const onSuggestionClicked = (suggestion: string) => {
		if (!!onSearchProp) {
			onSearchProp(suggestion);
		}
	};

	const toggleHistory = (): void => {
		setDynamicSuggestions([]);
		setProgress(true);
		searchService?.history().then((result) => {
			setDynamicSuggestions(result);
			setProgress(false);
		});
	};

	const deleteHistoryItem = (key: string): void => {
		// Delete locally first so user does not have to wait for feedback
		if (dynamicSuggestions) {
			let newDynamicSuggestions = dynamicSuggestions as ISuggestionItem[];
			newDynamicSuggestions = newDynamicSuggestions.filter((suggestionItem: ISuggestionItem) => {
				return suggestionItem.displayValue !== key;
			});
			setDynamicSuggestions(newDynamicSuggestions);
		}

		setProgress(true);
		searchService?.deleteHistoryItem(key).then(() => {
			searchService?.history().then((result) => {
				setDynamicSuggestions(result);
				setProgress(false);
			});
		});
	};

	const historyProps: IHistoryProps = {
		calloutTitle: "Recent searches",
		deleteHistoryItemIcon: deleteHistoryIcon,
		historyIcon: historyIcon,
		onDeleteHistoryItem: deleteHistoryItem,
		onHistoryButtonClicked: toggleHistory
	};

	return (
		<div className={classNames.root} ref={textInput}>
			<AutocompleteSearchBox
				className={classNames.searchBox}
				placeholder="Type one or more search terms"
				onSearch={handleSearch}
				onSuggestionClicked={onSuggestionClicked}
				onChange={onChange}
				suggestions={dynamicSuggestions}
				inProgress={inProgress}
				debounceTime={0}
				value={value}
				iconProps={searchIcon}
				clearButtonProps={clearButton}
				onClear={handleClear}
				id="SearchBar"
				historyProps={disablePersonalSearchHistory === true ? undefined : historyProps}
			/>
		</div>
	);
};
