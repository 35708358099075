import { IInfiniteScrollStyleProps, IInfiniteScrollStyles } from "./InfiniteScroll.types";

const GlobalClassNames = {
	root: "dw-InfiniteScroll"
};

export const getStyles = (props: IInfiniteScrollStyleProps): IInfiniteScrollStyles => {
	const { className } = props;
	const classNames = GlobalClassNames;

	return {
		root: [classNames.root, {}, className]
	};
};
