import { IFeedbackModalStyleProps, IFeedbackModalStyles } from "./FeedbackModal.types";

const GlobalClassNames = {
	root: "mse-FeedbackModal",
	separator: "mse-FeedbackModal-separator",
	modalTitle: "mse-FeedbackModal-modalTitle",
	modalText: "mse-FeedbackModal-modalText",
	modalButtons: "mse-FeedbackModal-modalButtons",
	modalClose: "mse-FeedbackModal-modalClose"
};

export const getStyles = (props: IFeedbackModalStyleProps): IFeedbackModalStyles => {
	const { className, theme } = props;
	const classNames = GlobalClassNames;

	return {
		root: [classNames.root, {}, className],
		separator:[classNames.root, {
			height: "18px"
		}],
		modalTitle: [classNames.modalTitle, {
			fontSize: "24px",
			lineHeight: "32px",
			margin: "20px 20px 0px 20px"
		}],
		modalText: [classNames.modalText, {
			margin: "28px 20px"
		}],
		modalButtons: [classNames.modalButtons, {
			margin: "20px"
		}],
		modalClose: [classNames.modalClose, {
			color: theme.palette.neutralPrimary,
			float: "right"
		}]
	};
};
