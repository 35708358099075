import { ILandingViewStyleProps, ILandingViewStyles } from "./LandingView.types";

const GlobalClassNames = {
	root: "mse-LandingView",
	other: "mse-LandingView-other",
	otherLinks: "mse-LandingView-otherLinks",
	link: "mse-LandingView-link",
	quickLink: "mse-LandingView-quickLink",
	quickLinkImage: "mse-LandingView-quickLinkImage",
	linkTitle: "mse-LandingView-linkTitle",
	otherHeader: "mse-LandingView-otherHeader",
	tooltipImage: "mse-LandingView-tooltipImage",
};

export const getStyles = (props: ILandingViewStyleProps): ILandingViewStyles => {
	const { className, theme } = props;
	const classNames = GlobalClassNames;

	return {
		root: [classNames.root, {
			fontFamily: theme.fonts.medium.fontFamily,
			backgroundColor: theme.semanticColors.bodyBackground,
			display: "flex",
			flexDirection: "column",
			width: "100%"
		}, className],
		other: [classNames.other, {
			backgroundColor: theme.semanticColors.bodyBackground,
			margin: "0",
			padding: "50px 20px",
			textAlign: "center",
			display: "flex",
			flexDirection: "column",
			flex: "1"
		}],
		otherHeader: [classNames.otherHeader, {
			color: theme.semanticColors.bodyText,
			fontFamily: theme.fonts.medium.fontFamily,
			fontStyle: "normal",
			fontWeight: "400",
			fontSize: "24px",
			lineHeight: "32px",
		}],
		otherLinks: [classNames.otherLinks, {
			color: theme.semanticColors.bodyText,
			paddingTop: "20px",
			justifyContent: "center",
    	display: "flex"
		}],
		link: [classNames.link, {
			width: "100px",
			"& a": {
				textDecoration: "none",
				color: theme.semanticColors.bodyText,
				display: "flex",
				flexDirection: "column",
				alignItems: "center"
			}
		}],
		linkTitle: [classNames.linkTitle, {
			fontFamily: theme.fonts.medium.fontFamily,
			fontStyle: "normal",
			fontWeight: "350",
			fontSize: "15px",
			lineHeight: "24px",
			marginTop: "5px",
			width: "100%",
			overflow: "hidden",
			textOverflow: "ellipsis"
		}],
		quickLink: [classNames.quickLink, {
			borderRadius: "50%",
			height: "70px",
			width: "70px"
		}],
		quickLinkImage: [classNames.quickLinkImage, {
			width: "30px",
			height: "30px",
			top: "20px",
			position: "relative"
		}],
		tooltipImage: [classNames.tooltipImage, {
			paddingLeft: "5px",
			width: "16px",
    	height: "16px"
		}]
	};
};
