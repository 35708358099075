import { ICheckboxStyleProps, ICheckboxStyles } from "office-ui-fabric-react";
import { IProfileFilterStyleProps, IProfileFilterStyles } from "./ProfileFilter.types";


const GlobalClassNames = {
	root: "mse-ProfileFilter",
	label: "mse-ProfileFilter-label",
	checkboxText: "mse-ProfileFilter-checkboxText",
	checkboxContainer: "mse-ProfileFilter-checkboxContainer",
	infoIcon: "mse-ProfileFilter-infoIcon"
};

export const getStyles = (props: IProfileFilterStyleProps): IProfileFilterStyles => {
	const { className, theme } = props;
	const classNames = GlobalClassNames;

	return {
		root: [classNames.root, {
			">label": {
				fontWeight: "normal",
				fontSize: "12px",
				paddingBottom: "0"
			}
		}, className],
		label: [classNames.label, {
			fontFamily: "Segoe UI",
			fontStyle: "normal",
			fontWeight: "400",
			fontSize: "12px",
			lineHeight: "16px",
			color: theme.semanticColors.bodySubtext,
			textAlign: "left"
		}],
		checkboxText: [classNames.checkboxText, {
			color: theme.semanticColors.bodySubtext
		}],
		checkboxContainer: [classNames.checkboxContainer, {
			display: "flex",
			height: "32px",
			alignItems: "center",
			marginTop: "3px"
		}],
		infoIcon: [classNames.infoIcon, {
			display: "flex",
			cursor: "pointer",
			fontSize: "18px",
			marginLeft: "5px",
			position: "relative",
			top: "2px",
			color: theme.semanticColors.bodySubtext
		}]
	};
};

export const checkboxStyles = (props: ICheckboxStyleProps): ICheckboxStyles => {
	
	return {
		root: {
			":hover .ms-Checkbox-checkbox": {
				background: "#FFFFFF",
				borderColor: "#FFFFFF"
			}
		},
		checkbox: {
			background: "#FFFFFF",
			borderColor: "#FFFFFF"
		},
		checkmark: {
			color: "#3F98BD",
			fontWeight: "bold"
		}
	};
}
