import { styled } from "office-ui-fabric-react/lib/Utilities";
import { NoResultsBase } from "./NoResults.base";
import { getStyles } from "./NoResults.styles";
import { INoResultsProps, INoResultsStyleProps, INoResultsStyles } from "./NoResults.types";

export const NoResults: React.FC<INoResultsProps> = styled<
	INoResultsProps,
	INoResultsStyleProps,
	INoResultsStyles
>(NoResultsBase, getStyles);
