import { app } from "@microsoft/teams-js";
import { classNamesFunction } from "office-ui-fabric-react/lib/Utilities";
import * as React from "react";
import { ImecButton } from "../../controls/ImecButton/ImecButton";
import { ImecModal } from "../../controls/ImecModal/ImecModal";
import Globals from "../../Globals";
import { useTheme } from "../../models/contexts/ThemeContext";
import { useLoggerService } from "../../services/logger";
import { ClientType, getClientType, isInTeams } from "../../utils";
import {
	IAuthenticationModalProps,
	IAuthenticationModalStyleProps,
	IAuthenticationModalStyles
} from "./AuthenticationModal.types";
const getClassNames = classNamesFunction<IAuthenticationModalStyleProps, IAuthenticationModalStyles>();

export const AuthenticationModalBase: React.FC<IAuthenticationModalProps> = (props) => {
	const redirectUrl = process.env.REACT_APP_AUTH_REDIRECT_URL;
	const resourceUrl = process.env.REACT_APP_AUTH_RESOURCE_URL;

	// State
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	const loggerService = useLoggerService();

	// Do not show if resource Url is empty or not in teams or on iOS mobile
	if (!isInTeams() || !resourceUrl || getClientType() === ClientType.TeamsClientAppIos) {
		return <></>;
	}

	// Styling initialization
	const { className, styles } = props;
	const theme = useTheme();
	const classNames = getClassNames(styles, { className, theme: theme! });

	// On resource load error
	const onResourceError = (ev: React.SyntheticEvent) => {
		loggerService?.trackEvent("SSO_AUTH_ERROR");
		setIsOpen(true);
	};

	return (
		<>
			<img width={0} height={0} onError={onResourceError} src={resourceUrl} />
			<ImecModal isOpen={isOpen} isBlocking={true} isModeless={true}>
				<>
					<div className={classNames.modalTitle}>Authentication Issue</div>
					<div className={classNames.modalText}>
						Since you recently signed out of imec Teams, you should go to the Home app to authenticate in order to view
						all imec Teams information.
					</div>
					<div className={classNames.modalButtons}>
						{!!redirectUrl && (
							<ImecButton
								text="Open Home app"
								onClick={() => {
									loggerService?.trackEvent(Globals.Telemetry.Events.ON_SSO_REDIRECT_CLICK);
									app.openLink(redirectUrl);
								}}
								target={"_self"}
							/>
						)}
					</div>
				</>
			</ImecModal>
		</>
	);
};
