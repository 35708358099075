// tslint:disable: jsx-ban-props
import { classNamesFunction } from "office-ui-fabric-react/lib/Utilities";
import * as React from "react";
import { ImecButton } from "../../controls/ImecButton/ImecButton";
import Globals from "../../Globals";
import { useTheme } from "../../models/contexts/ThemeContext";
import { useLoggerService } from "../../services/logger";
import { FeedbackModal } from "../FeedbackModal";
import { INoResultsProps, INoResultsStyleProps, INoResultsStyles } from "./NoResults.types";
const getClassNames = classNamesFunction<INoResultsStyleProps, INoResultsStyles>();

export const NoResultsBase: React.FC<INoResultsProps> = (props) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const loggerService = useLoggerService();

	// Styling initialization
	const { className, styles } = props;
	const theme = useTheme();
	const classNames = getClassNames(styles, { className, theme: theme! });

	const showFeedbackForm = () => {
		loggerService?.trackEvent(Globals.Telemetry.Events.ON_GIVE_FEEDBACK_CLICK);
		setIsOpen(true);
	};

	return (
		<div className={classNames.root}>
			<div>Sorry, we don't have any results for your search </div>
			<div className={classNames.feedbackButton}>
				<ImecButton text={"Give feedback"} onClick={showFeedbackForm} />
			</div>
			<FeedbackModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
		</div>
	);
};
