import Globals from "../../Globals";
import { IResultsViewStyleProps, IResultsViewStyles } from "./ResultsView.types";

const GlobalClassNames = {
	root: "mse-ResultsView",
	emergencyNumbers: "mse-ResultsView-emergencyNumbers",
	leftPart: "mse-ResultsView-left",
	rightPart: "mse-ResultsView-right",
	backButton: "mse-ResultsView-backButton",
	backButtonContainer: "mse-ResultsView-backButtonContainer",
	backImage: "mse-ResultsView-backImage",
};

export const getStyles = (props: IResultsViewStyleProps): IResultsViewStyles => {
	const { className, theme } = props;
	const classNames = GlobalClassNames;

	return {
		root: [classNames.root, {
			fontFamily: theme.fonts.medium.fontFamily,
			backgroundColor: theme.semanticColors.bodyBackground,
			display: "flex",
			flexDirection: "column",
			width: "100%"
		}, className],
		backButtonContainer: [classNames.backButtonContainer, {
			display: "flex",
			padding: "10px 8%",
			[Globals.Styling.MediaQueries.MobileSmall]: {
				padding: "10px 15px"
			}
		}],
		backButton: [
			classNames.backButton,
			{
				fontFamily: theme.fonts.medium.fontFamily,
				fontStyle: "normal",
				fontWeight: "400",
				fontSize: "14px",
				lineHeight: "20px",
				textDecoration: "none",
				color: theme.semanticColors.bodyText,
				cursor: "pointer",
			}
		],
		backImage: [
			classNames.backImage,
			{
				paddingRight: "5px",
				width: "11px",
				height: "9px",
			}
		],
	};
};
