import { classNamesFunction } from "office-ui-fabric-react/lib/Utilities";
import * as React from "react";
import { useTheme } from "../../models/contexts/ThemeContext";
import RenderIf from "../../utils/RenderIf";
import { IImecButtonProps, IImecButtonStyleProps, IImecButtonStyles } from "./ImecButton.types";

const getClassNames = classNamesFunction<IImecButtonStyleProps, IImecButtonStyles>();

export const ImecButtonBase: React.FC<IImecButtonProps> = (props) => {
	const { link, text, target, disabled } = props;
	const { onClick } = props;

	// Styling initialization
	const { className, styles } = props;
	const theme = useTheme();
	const classNames = getClassNames(styles, { className, theme: theme! });

	return (
		<>
			<RenderIf condition={!disabled}>
				<a className={classNames.root} href={!!onClick ? "#" : link} target={target} onClick={onClick}>
					{text}
				</a>
			</RenderIf>
			<RenderIf condition={disabled === true}>
				<span className={classNames.root}>
					{text}
				</span>
			</RenderIf>
		</>
	);
};
