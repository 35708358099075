import { INoResultsStyleProps, INoResultsStyles } from "./NoResults.types";


const GlobalClassNames = {
	root: "mse-NoResults-root",
	feedbackButton: "mse-NoResults-feedbackButton",
	modalTitle: "mse-NoResults-modalTitle",
	modalText: "mse-NoResults-modalText",
	modalButtons: "mse-NoResults-modalButtons",
	modalClose: "mse-NoResults-modalClose"
};

export const getStyles = (props: INoResultsStyleProps): INoResultsStyles => {
	const { theme } = props;
	const classNames = GlobalClassNames;

	return {
		root: [classNames.root, {
			fontFamily: theme.fonts.medium.fontFamily,
			fontStyle: "normal",
			fontWeight: "350",
			fontSize: "18px",
			lineHeight: "24px",
			width: "100%",
			textAlign: "center",
			color: theme.semanticColors.bodyText,
		}],
		feedbackButton: [classNames.feedbackButton, {
			display: "inline-block",
			marginTop: "10px"
		}],
		modalTitle: [classNames.modalTitle, {
			fontSize: "24px",
			lineHeight: "32px",
			margin: "20px 20px 0px 20px"
		}],
		modalText: [classNames.modalText, {
			margin: "15px 20px 0px 20px"
		}],
		modalButtons: [classNames.modalButtons, {
			margin: "20px"
		}],
		modalClose: [classNames.modalClose, {
			color: theme.palette.neutralPrimary,
			float: "right"
		}]
	};
};