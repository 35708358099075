// tslint:disable:no-any
export interface IFilter {
	fieldName: string;
	displayName: string;
	multivalue: boolean;
	filterType: FilterType;
	icons: IFilterIcon[];
	items: IFilterValue[];
}

export interface IFilterValue {
	text: string;
	key: string;
	value: string;
	count: number;
}

export interface IFilterIcon {
	iconName: string;
	key: string;
}

export enum FilterType {
	Text = 0,
	Date = 1,
	Profile = 2
}