// tslint:disable: no-any
// tslint:disable: no-function-expression
export class MsClarityProvider {
  public static setupMsClarity(
    clarityId?: string,
    userId?: string
  ): void {
    if (!!clarityId) {
      ((c, l, a, r, i, t?: any, y?: any) => {
        c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments); };
        t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", clarityId);

      if (!!userId) {
        (window as any).clarity("consent");
        (window as any).clarity("identify", userId.toString());
      }
    }
  }
}
