import { IconButton, MessageBar, MessageBarType, ProgressIndicator, TextField } from "office-ui-fabric-react";
import { classNamesFunction } from "office-ui-fabric-react/lib/Utilities";
import * as React from "react";
import { ImecButton } from "../../controls/ImecButton";
import { ImecModal } from "../../controls/ImecModal/ImecModal";
import Globals from "../../Globals";
import { useSearchService } from "../../models";
import { useTheme } from "../../models/contexts/ThemeContext";
import { useSearch } from "../../reducers";
import { IFeedbackInput } from "../../services";
import { useLoggerService } from "../../services/logger";
import RenderIf from "../../utils/RenderIf";
import { IFeedbackModalProps, IFeedbackModalStyleProps, IFeedbackModalStyles } from "./FeedbackModal.types";

const getClassNames = classNamesFunction<IFeedbackModalStyleProps, IFeedbackModalStyles>();

export const FeedbackModalBase: React.FC<IFeedbackModalProps> = (props) => {
	const { isOpen, isPositive } = props;
	if (!isOpen) {
		return <></>;
	}

	const { onClose } = props;
	const { currentQuery, filters, searchResult, page } = useSearch();

	// Styling initialization
	const { className, styles } = props;
	const theme = useTheme();
	const classNames = getClassNames(styles, { className, theme: theme! });

	// State
	const [feedbackInput, setFeedbackInput] = React.useState<IFeedbackInput>({
		what: "",
		how: currentQuery ? currentQuery : "",
		feedback: "",
		filter: filters.reduce((result, item) => `${result}${item.fieldName},${item.key}|`, ""),
		noResults: (searchResult?.totalCount ?? 0) === 0
	});
	const [canSend, setCanSend] = React.useState(false);
	const [isSending, setIsSending] = React.useState(false);
	const [sendFeedbackFailed, setSendFeedbackFailed] = React.useState(false);
	const [isFeedbackFormOpen, setIsFeedbackFormOpen] = React.useState(isPositive ? false : true);
	const [isConfirmationOpen, setIsConfirmationOpen] = React.useState(isPositive ? true : false);

	// Services
	const searchService = useSearchService();
	const loggerService = useLoggerService();

	// Track positive feedback
	if (isPositive) {
		loggerService?.trackEvent(Globals.Telemetry.Events.ON_SEND_FEEDBACK_CLICK, {
			querytext: currentQuery,
			filters: JSON.stringify(filters),
			page: page?.toString() ?? "",
			resultCount: searchResult?.totalCount?.toString() ?? "",
			isPositive: "1"
		});
	}

	// Functions
	const validate = () => {
		if (feedbackInput.how.length > 0 && feedbackInput.what.length > 0) {
			setCanSend(true);
		}
	};
	const sendFeedback = () => {
		setIsSending(true);
		setCanSend(false);
		loggerService?.trackEvent(Globals.Telemetry.Events.ON_SEND_FEEDBACK_CLICK, {
			querytext: currentQuery,
			filters: JSON.stringify(filters),
			page: page?.toString() ?? "",
			resultCount: searchResult?.totalCount?.toString() ?? "",
			isPositive: "0"
		});

		let feedbackFailed = false;
		searchService
			?.sendFeedback(feedbackInput)
			.catch(() => {
				feedbackFailed = true;
			})
			.then(() => {
				setSendFeedbackFailed(feedbackFailed);
				setIsSending(false);
				if (!feedbackFailed) {
					setIsFeedbackFormOpen(false);
					setIsConfirmationOpen(true);
				}
			});
	};
	const closeForm = () => {
		// Reset form
		setFeedbackInput({
			what: "",
			how: currentQuery ? currentQuery : "",
			feedback: "",
			noResults: (searchResult?.totalCount ?? 0) === 0,
			filter: filters.reduce((result, item) => `${result}${item.fieldName},${item.key}|`, "")
		});
		setIsFeedbackFormOpen(true);
		setIsConfirmationOpen(false);
		setSendFeedbackFailed(false);
		setIsSending(false);
		setCanSend(false);
		onClose();
	};

	return (
		<>
			<ImecModal isOpen={isFeedbackFormOpen}>
				<RenderIf condition={sendFeedbackFailed}>
					<MessageBar messageBarType={MessageBarType.error} truncated={true} isMultiline={false}>
						<strong>Sending feedback has failed, please try again. Contact administrator if the issue persists.</strong>
					</MessageBar>
				</RenderIf>
				<div className={classNames.modalTitle}>
					Give feedback
					<span className={classNames.modalClose}>
						<IconButton iconProps={{ iconName: "ImecClose" }} ariaLabel="Close popup modal" onClick={() => onClose()} />
					</span>
				</div>
				<RenderIf condition={isSending}>
					<ProgressIndicator />
				</RenderIf>
				<RenderIf condition={!isSending}>
					<div className={classNames.separator} />
				</RenderIf>
				<div className={classNames.modalText}>
					<TextField
						label="What information were you looking for?"
						value={feedbackInput.what}
						disabled={isSending}
						required
						onChange={(ev, value) => {
							setFeedbackInput({ ...feedbackInput, what: value || "" });
							validate();
						}}
					/>
					<TextField
						label="What keywords did you enter in the search bar? (put a ',' between different queries)"
						value={feedbackInput.how}
						disabled={isSending}
						required
						onChange={(ev, value) => {
							setFeedbackInput({ ...feedbackInput, how: value || "" });
							validate();
						}}
					/>
					<TextField
						label="Additional feedback"
						value={feedbackInput.feedback}
						disabled={isSending}
						multiline
						onChange={(ev, value) => {
							setFeedbackInput({ ...feedbackInput, feedback: value || "" });
						}}
					/>
				</div>
				<div className={classNames.modalButtons}>
					<ImecButton disabled={!canSend} text="Send feedback" onClick={() => sendFeedback()} />
				</div>
			</ImecModal>
			<ImecModal isOpen={isConfirmationOpen} maxWidth={"500px"} minHeight={"80px"}>
				<div className={classNames.modalTitle}>
					<span className={classNames.modalClose}>
						<IconButton iconProps={{ iconName: "ImecClose" }} ariaLabel="Close popup modal" onClick={closeForm} />
					</span>
				</div>
				<div className={classNames.modalText}>
					{isPositive
						? "Thank you for your feedback!"
						: "Thank you for your feedback! This helps us to further improve the imec Search."}
				</div>
			</ImecModal>
		</>
	);
};
