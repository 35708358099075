import { IAuthenticationModalStyleProps, IAuthenticationModalStyles } from "./AuthenticationModal.types";


const GlobalClassNames = {
	root: "mse-AuthenticationModal-root",
	modalClose: "mse-AuthenticationModel-close",
	modalRoot: "mse-AuthenticationModal-modalRoot",
	modalTitle: "mse-AuthenticationModal-modalTitle",
	modalText: "mse-AuthenticationModal-modalText",
	modalButtons: "mse-AuthenticationModal-modalButtons"
};

export const getStyles = (props: IAuthenticationModalStyleProps): IAuthenticationModalStyles => {
	const { className } = props;
	const classNames = GlobalClassNames;

	return {
		root: [classNames.root, { zIndex: 9 }, className],
		modalRoot: [classNames.root, {}],
		modalClose: [classNames.modalClose, {
			position: "relative",
			right: "8px",
			float: "right",
			top: "8px",
			cursor: "pointer"
		}],
		modalTitle: [classNames.modalTitle, {
			fontSize: "24px",
			lineHeight: "32px",
			margin: "20px 20px 0px 20px"
		}],
		modalText: [classNames.modalText, {
			margin: "20px 20px 0px 20px"
		}],
		modalButtons: [classNames.modalButtons, {
			margin: "20px"
		}]
	};
};