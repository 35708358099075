// tslint:disable:jsx-ban-props
import { app } from "@microsoft/teams-js";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { classNamesFunction } from "office-ui-fabric-react/lib/Utilities";
import * as React from "react";
import Globals from "../../Globals";
import { useTheme } from "../../models/contexts/ThemeContext";
import { useSearch } from "../../reducers";
import { useLoggerService } from "../../services/logger";
import Symbols from "../../svg-symbols/Symbols";
import { isInTeams } from "../../utils";
import { ISearchResultItemProps, ISearchResultItemStyleProps, ISearchResultItemStyles } from "./SearchResultItem.types";

const getClassNames = classNamesFunction<ISearchResultItemStyleProps, ISearchResultItemStyles>();

export const SearchResultItemBase: React.FC<ISearchResultItemProps> = (props) => {
	const { isSelected, item, className, styles, synonyms } = props;
	const { currentQueryWords } = useSearch();
	const theme = useTheme();
	const loggerService = useLoggerService();
	const classNames = getClassNames(styles, { className, theme: theme!, isSelected });

	// Date
	const getDate = (): string | undefined => {
		if (!!item.date && item.date.indexOf("01-01-01T00:00:00Z") < 0) {
			if (!!item.dateLocale && item.dateLocale === "Text") {
				return item.date;
			} else {
				const date = new Date(item.date);
				const month = date.toLocaleString("en-us", { month: "short" });
				const day = date.toLocaleString("en-us", { day: "numeric" });
				const year = date.toLocaleString("en-us", { year: "numeric" });

				return `${month} ${day}, ${year}`;
			}
		}
		return undefined;
	};

	// Add synonyms to querywords if any
	let allWordsToHiglight = currentQueryWords;
	if (synonyms && synonyms.length > 0) {
		allWordsToHiglight = allWordsToHiglight.concat(synonyms);
	}

	// Highlight title words
	const highlightedTitle = item.title?.replace(
		new RegExp(`\\b(${allWordsToHiglight.map(w => w.replace('*', '\\\\*')).join("|")})\\b`, "gi"),
		(match: string) => {
			return `<span class="${classNames.highlight}">${match}</span>`;
		}
	);

	// Highlight description
	let highlightedDescription = item.description
		?.replace(new RegExp("<c0>", "g"), `<span class='${classNames.highlight}'>`)
		.replace(new RegExp("</c0>", "g"), "</span>");
	highlightedDescription = highlightedDescription?.replace(new RegExp("<ddd/>", "g"), "...");

	// On item click
	const onItemClick = (openInBorwser: boolean = false): void => {
		// Open in browser if coming from the 'open in browser' button
		if (openInBorwser) {
			// Log click event
			loggerService?.trackEvent(Globals.Telemetry.Events.ON_ITEM_OPEN_IN_BROWSER_CLICK, {
				payload: item.title ?? ""
			});

			window.open(item.link, "_blank");
		} else {
			// Log click event
			loggerService?.trackEvent(Globals.Telemetry.Events.ON_ITEM_CLICK, {
				payload: item.title ?? ""
			});

			// Deeplink in MS Teams if in MS Teams and deeplink is available
			if (isInTeams() && item.isTeamsDeeplink && !!item.link) {
				app.openLink(item.link);
			} else {
				if (item.openBehaviour === "_blank") {
					window.open(item.link, "_blank");
				} else if (!!item.link) {
					window.location.href = item.link;
				}
			}
		}
	};

	return (
		<div className={classNames.root}>
			<div className={classNames.icon}>
				<Icon iconName={item.icon} />
			</div>
			<div className={classNames.resultType}>
				<div className={classNames.resultTypeBadge}>{item.type}</div>
				<div className={classNames.resultTypeByLine}>
					<div className={classNames.resultTypeDate}>{getDate()}</div>
					{item.showOpenInBrowserLink && (
						<div className={classNames.resultTypeOpenBrowserIcon}>
							<a
								href={item.link}
								onClick={(ev) => {
									ev.preventDefault();
									onItemClick(true);
								}}
							>
								<svg className={classNames.worldIcon}>
									<use xlinkHref={Symbols.Icons.IconWorld} />
								</svg>
							</a>
						</div>
					)}
				</div>
			</div>
			<div className={classNames.title}>
				<div
					className={classNames.titleText}
					dangerouslySetInnerHTML={{ __html: highlightedTitle ? highlightedTitle : "" }}
					onClick={() => onItemClick()}
				/>
			</div>
			<div
				className={classNames.description}
				dangerouslySetInnerHTML={{ __html: highlightedDescription ? highlightedDescription : "" }}
				onClick={() => onItemClick()}
			/>
			{!!item.previewImageUrl && (
				<div
					style={{ background: `url("${item.previewImageUrl}") center center / cover` }}
					className={classNames.image}
					onClick={() => onItemClick()}
				/>
			)}
		</div>
	);
};
