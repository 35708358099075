import Globals from "../../Globals";
import { ISearchContainerStyleProps, ISearchContainerStyles } from "./SearchContainer.types";

const GlobalClassNames = {
	root: "mse-SearchContainer",
	wrapper: "mse-wrapper",
	wrapperSmall: "mse-wrapper-small",
	searchBoxContainer: "mse-searchBoxContainer",
	searchTitle: "mse-searchTitle",
	openBrowser: "mse-openBrowser",
	worldIcon: "mse-worldIcon",
	querySuggestion: "mse-querySuggestion",
	querySuggestionContainer: "mse-querySuggestionContainer",
};

export const getStyles = (props: ISearchContainerStyleProps): ISearchContainerStyles => {
	const { className, theme } = props;
	const classNames = GlobalClassNames;
	const backgroundImg = theme.id === "dark" 
		? "linear-gradient(112.32deg, #3C3C3B 7.52%, #3C3C3B 90.36%)"
		: "url(headerbackground.png),linear-gradient(112.32deg, #3F98BD 7.52%, #52BDC2 90.36%)"

	return {
		root: [
			classNames.root,
			{
				display: "flex",
				flexWrap: "wrap",
				width: "100%"
			},
			className
		],
		wrapper: [
			classNames.wrapper,
			{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				width: "100%",
				height: "400px",
				paddingLeft: "8%",
				paddingRight: "8%",
				background: backgroundImg
			}
		],
		wrapperSmall: [
			classNames.wrapperSmall,
			{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				width: "100%",
				height: "180px",
				paddingLeft: "8%",
				paddingRight: "8%",
				background: backgroundImg,
				[Globals.Styling.MediaQueries.MobileSmall]: {
					paddingLeft: "15px",
					paddingRight: "15px",
				}
			}
		],
		searchTitle: [
			classNames.searchTitle,
			{
				color: "#fff",
				fontFamily: theme.fonts.medium.fontFamily,
				fontStyle: "normal",
				fontWeight: "400",
				fontSize: "32px",
				lineHeight: "40px",
			}
		],
		querySuggestion: [
			classNames.querySuggestion,
			{
				color: "#fff",
				fontFamily: theme.fonts.medium.fontFamily,
				fontStyle: "normal",
				fontWeight: "400",
				cursor: "pointer",
			}
		],
		searchBoxContainer: [
			classNames.searchBoxContainer, {
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				height: "120px"
			}
		],
		querySuggestionContainer:[
			classNames.querySuggestionContainer,
			{
				color: "#fff",
				paddingBottom: "5px"
			}
		],
		openBrowser: [
			classNames.openBrowser,
			{
				position: "absolute",
				top: "20px",
				right: "32px",
				fontSize: "14px",
				">a": {
					color: "white"
				}
			}
		],
		worldIcon: [
			classNames.worldIcon, {
				width: "12px",
				height: "12px",
				color: "white",
				marginRight: "10px",
				position: "relative",
				top: "1px"
			}
		]
	};
};
