import Globals from "../../Globals";
import { ISuggestionItem } from "../../components/AutoCompleteSearchBox/AutoCompleteSearchBox.types";
import { IDeleteActivityModel } from "../../models/search/IDeleteActivityModel";
import { IQueryInputModel } from "../../models/search/IQueryInputModel";
import { IQuerySuggestionResponse } from "../../models/search/IQuerySuggestionResponse";
import { ISearchResult } from "../../models/search/SearchResult";
import { ILoggerService } from "../logger";
import { IFeedbackInput, ISearchQueryInput, ISearchService } from "./ISearchService";
import { SearchServiceBase } from "./SearchServiceBase";

export class SearchService extends SearchServiceBase implements ISearchService {
	private className = "SearchService";

	constructor(
		loggerService: ILoggerService,
		apiAccessTokenProvider: (() => PromiseLike<string>)
	) {
		super(loggerService, apiAccessTokenProvider);
	}

	public async autoComplete(queryInput: string): Promise<string[]> {
		const url = process.env.REACT_APP_WEBAPI_BASEURI ?? "";

		try {
			const result = (await this.executeGet<string[]>(`${url}/api/Autocomplete`, `text=${queryInput}`)) as string[];
			return result
		} catch (error) {
			throw this.loggerService?.trackException(error);
		}
	}

	public async suggest(queryInput: string): Promise<IQuerySuggestionResponse> {
		const url = process.env.REACT_APP_WEBAPI_BASEURI ?? "";
		try {
			const result = (
				await this.executeGet<IQuerySuggestionResponse>(`${url}/api/search/suggest`, `text=${queryInput}`)
			) as IQuerySuggestionResponse;

			const allSuggestions = result?.suggestions?.concat(result?.noResultsSuggestions ?? []) ?? [];
			if (allSuggestions.length > 0) {
				this.loggerService?.trackEvent(Globals.Telemetry.Events.ON_SUGGESTION_SHOWN, {
					payload: allSuggestions.map(s => `${s?.suggestion}${s?.fromGraph ? " (from Graph)" : " (from Keywords)"}`)?.join(", ") ?? ""
				});
			}

			return result
		} catch (error) {
			throw this.loggerService?.trackException(error);
		}
	}

	public async history(): Promise<ISuggestionItem[]> {
		const url = process.env.REACT_APP_WEBAPI_BASEURI ?? "";

		try {
			const result = (await this.executeGet<ISuggestionItem[]>(`${url}/api/Activities/Recent`)) as ISuggestionItem[];
			return result
		} catch (error) {
			throw this.loggerService?.trackException(error);
		}
	}

	public async deleteHistoryItem(key: string): Promise<boolean> {
		const body: IDeleteActivityModel = { activityId: key };
		const url = process.env.REACT_APP_WEBAPI_BASEURI ?? "";

		try {
			const result =
				(await this.executePost<boolean, IDeleteActivityModel>(`${url}/api/activities/delete`, body)) as boolean;
			return result
		} catch (error) {
			throw this.loggerService?.trackException(error);
		}
	}

	public async sendFeedback(queryInput: IFeedbackInput): Promise<boolean> {

		const url = process.env.REACT_APP_WEBAPI_BASEURI ?? "";

		const logProps = {
			className: this.className,
			action: "SearchServiceFeedback"
		};

		try {
			return (await this.executePost<boolean, IFeedbackInput>(`${url}/api/feedback`, queryInput)) as boolean;
		} catch (error) {
			throw this.loggerService?.trackException(error, logProps);
		}
	}

	public async executeQuery(queryInput: ISearchQueryInput, enableLogging: boolean = true): Promise<ISearchResult> {
		const body: IQueryInputModel = { ...queryInput };
		const logProps = {
			className: this.className,
			action: "SearchService",
			querytext: queryInput.queryText,
			filters: JSON.stringify(queryInput.filters),
			page: queryInput.page?.toString() ?? "",
			isQueryModificationRequest: queryInput.isQueryModificationRequest?.toString() ?? "",
			queryModificationOriginalQueryText: queryInput.queryModificationOriginalQueryText ?? ""
		};

		const url = process.env.REACT_APP_WEBAPI_BASEURI ?? "";

		try {
			const result = (await this.executePost<ISearchResult, IQueryInputModel>(`${url}/api/search`, body)) as ISearchResult;
			if (enableLogging) {
				this.loggerService?.trackEvent(Globals.Telemetry.Events.ON_QUERY_SEARCHED, {
					...logProps,
					resultCount: result?.totalCount?.toString() ?? "",
					expandedQuery: result?.expandedQuery ?? ""
				});
			}
			return result
		} catch (error) {
			throw this.loggerService?.trackException(error, logProps);
		}
	}
}
