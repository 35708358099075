import Globals from "../../Globals";
import { ISearchFiltersContainerStyleProps, ISearchFiltersContainerStyles } from "./SearchFiltersContainer.types";

const GlobalClassNames = {
	searchFiltersContainer: "mse-SearchFiltersContainer",
	searchFilters: "mse-SearchFiltersContainer-searchFilters",
	searchFilter: "mse-SearchFiltersContainer-searchFilter",
	selectedFilter: "mse-SearchFiltersContainer-selectedFilter",
	selectedFiltersContainer: "mse-SearchFiltersContainer-selectedFiltersContainer",
	selectedFiltersContainerLabel: "mse-SearchFiltersContainer-selectedFiltersContainerLabel",
	removeFilterButton: "mse-SearchFiltersContainer-removeFilterButton",
	clearIcon: "mse-SearchFiltersContainer-clearIcon",
};

export const getStyles = (props: ISearchFiltersContainerStyleProps): ISearchFiltersContainerStyles => {
	const { className, theme } = props;
	const classNames = GlobalClassNames;

	return {
		searchFiltersContainer: [classNames.searchFiltersContainer, {
			display: "flex",
			flexDirection: "column",
			paddingBottom: "20px"
		}, className],
		searchFilters: [classNames.searchFilters, {
			display: "flex",
			width: "100%",
			flexDirection: "row",
			flexWrap: "wrap"
		}],
		searchFilter: [classNames.searchFilter, {
			width: "240px",
			paddingRight: "10px;",
			[Globals.Styling.MediaQueries.MobileSmall]: {
				padding: "0",
				width: "100%"
			}
		}],
		selectedFilter: [classNames.selectedFilter, {
			fontFamily: theme.fonts.medium.fontFamily,
			fontStyle: "normal",
			fontSize: "12px",
			lineHeight: "32px",
			textAlign: "center",
			color: "#34495E",
			background: "#EDF1F3",
			padding: "2px 6px 2px 5px",
			marginRight: "5px",
			marginTop: "4px",
			width: "fit-content",
			height: "32px",
		}],
		selectedFiltersContainer: [classNames.selectedFiltersContainer, {
			marginTop: "20px",
			display: "flex",
			flexDirection: "row",
			flexWrap: "wrap"
		}],
		selectedFiltersContainerLabel: [classNames.selectedFiltersContainerLabel, {
			height: "32px",
			marginTop: "4px",
			lineHeight: "32px",
			padding: "2px 6px 2px 0px",
			color: theme.semanticColors.bodySubtext
		}],
		removeFilterButton: [classNames.removeFilterButton, {
			cursor: "pointer",
			fontWeight: "700",
			marginLeft: "5px"
		}],
		clearIcon: [classNames.clearIcon, {
			width: "11px",
			height: "11px",
			position: "relative",
			top: "1px",
			marginLeft: "3px"
		}]
	};
};
