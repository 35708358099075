const Globals = {
	CacheKeys: {
		PROFILE_PROPERTIES: "imec-search-profile-props",
		QUERY_ID: "imec-search-query-id"
	},
	Fields: {
		ImecEventStartDate: "imecEventStartDate",
		ImecPublicationDate: "imecPublicationDate",
		CsModifiedDate: "csModifiedDate",
	},
	Filters: {
		Category: "Category",
		Filetype: "Filetype",
		ProfileFilter: "ProfileFilter"
	},
	Formatting: {
		NumberFormatLocale: "en-US"
	},
	DefaultProfileFilter: {
		filter: {
			fieldName: "ProfileFilter",
			displayName: "Profile filter",
			text: "ProfileFilter",
			key: "profilefilter-ProfileFilter",
			value: "true",
			count: -1
		},
		enabledCategories: ["Information", "News", "Event", "Tool"]
	},
	ResultTypes: {
		News: "News",
		Events: "Events",
		Material: "Material",
		Curriculum: "Curriculum",
		Course: "Course",
		Event: "Event",
		Job: "Job"
	},
	Styling: {
		MediaQueries: {
			MobileSmall: "@media (max-width:600px)"
		}
	},
	Telemetry: {
		Metrics: {
			FE_API_SEARCH_TIME: "FE_API_SEARCH_TIME",
			FE_API_SUGGEST_TIME: "FE_API_SUGGEST_TIME"
		},
		Events: {
			ON_ITEM_CLICK: "ON_ITEM_CLICK",
			ON_ITEM_OPEN_IN_BROWSER_CLICK: "ON_ITEM_OPEN_IN_BROWSER_CLICK",
			ON_QUERY_SEARCHED: "ON_QUERY_SEARCHED",
			ON_SSO_REDIRECT_CLICK: "ON_SSO_REDIRECT_CLICK",
			ON_QUICK_LINK_CLICK: "ON_QUICK_LINK_CLICK",
			ON_PERSONAL_HISTORY_CLICK: "ON_PERSONAL_HISTORY_CLICK",
			ON_PERSONAL_HISTORY_ITEM_CLICK: "ON_PERSONAL_HISTORY_ITEM_CLICK",
			ON_OPEN_IN_BROWSER_CLICK: "ON_OPEN_IN_BROWSER_CLICK",
			ON_CATEGORY_FILTER_SELECT: "ON_CATEGORY_FILTER_SELECT",
			ON_FILETYPE_FILTER_SELECT: "ON_FILETYPE_FILTER_SELECT",
			ON_BACK_TO_HOME_CLICK: "ON_BACK_TO_HOME_CLICK",
			ON_GIVE_FEEDBACK_CLICK: "ON_GIVE_FEEDBACK_CLICK",
			ON_SEND_FEEDBACK_CLICK: "ON_SEND_FEEDBACK_CLICK",
			ON_TEAMS_APP_ICON_CLICK: "ON_TEAMS_APP_ICON_CLICK",
			ON_SUGGESTION_CLICK: "ON_SUGGESTION_CLICK",
			ON_SUGGESTION_SHOWN: "ON_SUGGESTION_SHOWN",
			ON_AUTOCOMPLETE_CLICK: "ON_AUTOCOMPLETE_CLICK"
		}
	}
}
export default Globals
