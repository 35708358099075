// tslint:disable: jsx-ban-props
import { app } from "@microsoft/teams-js";
import { ITooltipHostStyles, TooltipHost } from "office-ui-fabric-react/lib/Tooltip";
import { classNamesFunction } from "office-ui-fabric-react/lib/Utilities";
import * as React from "react";
import Globals from "../../Globals";
import { SearchContainer } from "../../components/SearchContainer";
import { useApiAccessTokenProvider, useSearchService, useTheme, useViewType } from "../../models";
import { IQuickLink } from "../../models/IQuickLinks";
import { ViewType } from "../../models/ViewType";
import { useLoggerService } from "../../services/logger";
import { QuickLinkService } from "../../services/quickLinks/QuickLinkService";
import Symbols from "../../svg-symbols/Symbols";
import { ClientType, getClientType } from "../../utils/ClientType";
import { ILandingViewProps, ILandingViewStyleProps, ILandingViewStyles } from "./LandingView.types";

const getClassNames = classNamesFunction<ILandingViewStyleProps, ILandingViewStyles>();

export const LandingViewBase: React.FC<ILandingViewProps> = (props) => {
	const [quickLinks, setQuickLinks] = React.useState<IQuickLink[] | undefined>(undefined);
	const [, setViewType] = useViewType();
	const searchService = useSearchService();
	const loggerService = useLoggerService();
	const apiAccessTokenProvider = useApiAccessTokenProvider();

	// Styling initialization
	const { className, styles, disablePersonalSearchHistory } = props;
	const theme = useTheme();
	const classNames = getClassNames(styles, { className, theme: theme! });
	const tooltipStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };
	const calloutProps = { gapSpace: 0 };

	// Event handlers
	const onSearch = () => {
		setViewType(ViewType.results);
	};

	const onQuickLinkClick = (url: string, name: string): void => {
		const clientType = getClientType();
		loggerService?.trackEvent(Globals.Telemetry.Events.ON_QUICK_LINK_CLICK, { payload: name });
		if(clientType === ClientType.Browser || clientType === ClientType.SharePointMobileApp){
			window.open(url, "_blank");
		}
		else{
			app.openLink(url);
		}
	};

	// Fire search query on first load
	React.useEffect(() => {
		searchService?.executeQuery({ queryText: "*" }, false);
		const init = async () => {
			if (!!apiAccessTokenProvider) {
				try {
					const quickLinkService = new QuickLinkService(apiAccessTokenProvider);
					const allQuickLinks = await quickLinkService.getQuickLinks();
					setQuickLinks(allQuickLinks);
				} catch (error) {
					setQuickLinks([]);
				}
			}
		};
		init();
	}, []);

	return (
		<div className={classNames.root}>
			<SearchContainer
				disablePersonalSearchHistory={disablePersonalSearchHistory}
				title="Hello! What corporate imec information are you looking for?"
				big={true}
				onExecuteSearch={onSearch}
				styles={{ root: { margin: "auto" } }}
			/>
			<div className={classNames.other}>
				{!!quickLinks && quickLinks.length > 0 && (
					<>
						<div className={classNames.otherHeader}>
							Search within other sources
							<TooltipHost
								content="These databases are not included in the general imec search. If you are looking for publications or a colleague's contact details, you can search via these buttons."
								id="tooltip-other-databases"
								closeDelay={500}
								calloutProps={calloutProps}
								styles={tooltipStyles}
							>
								<svg className={classNames.tooltipImage}>
									<use xlinkHref={Symbols.Icons.IconTooltip} />
								</svg>
							</TooltipHost>
						</div>
						<div className={classNames.otherLinks}>
							{quickLinks
								.sort((a: IQuickLink, b: IQuickLink) => {
									if (a.sortOrder > b.sortOrder) {
										return 1;
									}
									if (a.sortOrder < b.sortOrder) {
										return -1;
									}
									return 0;
								})
								.map((quickLink: IQuickLink, index: number) => {
									const style = { backgroundColor: quickLink.backgroundColor };
									return (
										<div className={classNames.link} key={index}>
											<a
												href={quickLink.url}
												onClick={(ev) => {
													ev.preventDefault();
													onQuickLinkClick(quickLink.url, quickLink.title);
												}}
											>
												<div className={classNames.quickLink} style={style}>
													<img className={classNames.quickLinkImage} src={quickLink.iconUrl} alt={quickLink.title} />
												</div>
												<div className={classNames.linkTitle}>{quickLink.title}</div>
											</a>
										</div>
									);
								})}
						</div>
					</>
				)}
			</div>
		</div>
	);
};
