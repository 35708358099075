import Axios from "axios";
import {
	Callout,
	classNamesFunction,
	DefaultEffects,
	DirectionalHint,
	FocusZone,
	FocusZoneDirection,
	FocusZoneTabbableElements,
	ICalloutContentStyles,
	IconButton,
	IFocusZone,
	IProgressIndicatorStyles,
	KeyCodes,
	Link,
	ProgressIndicator,
	SearchBox
} from "office-ui-fabric-react";
import * as React from "react";
import Globals from "../../Globals";
import { useTheme } from "../../models";
import { useSearch } from "../../reducers";
import { useLoggerService } from "../../services/logger";
import HighlightTextView from "../../utils/HighlightTextView";
import RenderIf from "../../utils/RenderIf";
import {
	IAutoCompleteSearchBoxProps,
	IAutoCompleteSearchBoxStyleProps,
	IAutoCompleteSearchBoxStyles,
	ISuggestionItem
} from "./AutoCompleteSearchBox.types";

const getClassNames = classNamesFunction<IAutoCompleteSearchBoxStyleProps, IAutoCompleteSearchBoxStyles>();

export const AutocompleteSearchBoxBase: React.FC<IAutoCompleteSearchBoxProps> = (props) => {
	const { historyProps: historyProps, onSearch: onSearchProp, value } = props;
	const { isSearching } = useSearch();
	const textInput = React.useRef<HTMLDivElement>(null);
	const [isCalloutFocussed, setCalloutFocussed] = React.useState(false);
	const [isCallOutVisible, setIsCallOutVisible] = React.useState(false);
	const [calloutTitle, setCalloutTitle] = React.useState("");
	const [isLoading, setIsLoading] = React.useState(true);
	const [showLoader, setShowLoader] = React.useState(true);
	const [query, setQuery] = React.useState("");
	const focusZoneRef = React.useRef<IFocusZone>(null);
	const [suggestions, setSuggestions] = React.useState<string[] | ISuggestionItem[]>();
	const [suggestionClicked, setSuggestionClicked] = React.useState(false);
	const [searchQuery, setSearchQuery] = React.useState<string | undefined>(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const urlQuery = urlParams.get("query");
		if (!!urlQuery) {
			return urlQuery;
		}
		return value;
	});

	const loggerService = useLoggerService();

	// Styling initialization
	const { className, styles } = props;
	const theme = useTheme();
	const classNames = getClassNames(styles, { className, theme: theme! });

	React.useEffect(() => {
		setSuggestions(props.suggestions);
		setIsCallOutVisible(props.suggestions !== undefined);
	}, [props.suggestions]);

	React.useEffect(() => {
		setIsLoading(props.inProgress === true ? true : false);
	}, [props.inProgress]);

	React.useEffect(() => {
		setSearchQuery(props.value);
	}, [props.value]);

	const ProgressIndicatorStyle: Partial<IProgressIndicatorStyles> = {
		itemProgress: {
			paddingBottom: "4px"
		}
	};

	const getCalloutWidth = () => {
		let calloutWidth = textInput.current?.offsetWidth;
		if (calloutWidth) {
			calloutWidth = calloutWidth + 16;
		}
		return calloutWidth + "px!important";
	};

	const typeAheadCalloutStyle: Partial<ICalloutContentStyles> = {
		root: {
			boxShadow: DefaultEffects.elevation4,
			borderRadius: 5,
			marginTop: 0,
			width: getCalloutWidth(),
			minWidth: "200px",
			overflow: "hidden",
			top: "-51px !important",
			left: "-8px !important",
			backgroundColor: "#FFF",
			selectors: {
				"@media(max-width: 600px)": {
					top: "0px",
					left: "0px!important",
					minWidth: "200px"
				}
			}
		},
		container: {
			zIndex: 1,
			position: "relative"
		},
		calloutMain: {
			minHeight: "fit-content",
			maxHeight: "500px!important",
			height: "100%",
			marginTop: "55px"
		}
	};
	const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
		setSuggestionClicked(false);
		setIsCallOutVisible(false);
		if (props.onFocus) {
			props.onFocus(event);
		}
	};

	const onKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
		// tslint:disable-next-line: deprecation
		switch (ev.which) {
			case KeyCodes.down: {
				setCalloutFocussed(true);
				focusZoneRef.current?.focus();
				ev.preventDefault();
				break;
			}
			default:
				setCalloutFocussed(false);
		}
	};

	const renderProgressIndicator = () => {
		if (isLoading) {
			return <ProgressIndicator styles={ProgressIndicatorStyle} />;
		}
		// tslint:disable-next-line: jsx-ban-props
		return <div style={{ height: "14px" }} />;
	};

	const onSuggestionClicked = (suggestion: string, isPersonalHistoryItem: boolean) => {
		loggerService?.trackEvent(
			isPersonalHistoryItem
				? Globals.Telemetry.Events.ON_PERSONAL_HISTORY_ITEM_CLICK
				: Globals.Telemetry.Events.ON_AUTOCOMPLETE_CLICK
			, { payload: suggestion }
		);

		setSuggestionClicked(true);
		setQuery(suggestion);
		props.onSuggestionClicked(suggestion);
		hideSuggestions();
	};

	const hideSuggestions = () => {
		setIsCallOutVisible(false);
	};

	const renderSuggestions = () => {
		const views: JSX.Element[] = [];
		if (!suggestions) {
			return <></>;
		}
		suggestions.forEach((suggestion: string | ISuggestionItem, i: number) => {
			views.push(getDefaultListItem(suggestion, i));
		});

		return views;
	};

	function onHistoryDeleteClick(key: string): void {
		if (historyProps) {
			setShowLoader(true);
			setCalloutTitle(historyProps.calloutTitle);
			historyProps.onDeleteHistoryItem(key);
		}
	}

	const getDefaultListItem = (suggestion: string | ISuggestionItem, key: number) => {
		if (typeof suggestion === "string") {
			return (
				<div className={classNames.suggestion} role="listitem" key={key}>
					<Link onClick={(e) => onSuggestionClicked(suggestion, false)} className={classNames.suggestionLink}>
						<HighlightTextView text={suggestion} filter={query} />
					</Link>
				</div>
			);
		} else {
			return (
				<div className={classNames.suggestion} role="listitem" key={key}>
					<Link onClick={(e) => onSuggestionClicked(suggestion.displayValue, true)} className={classNames.suggestionLink}>
						<span className={classNames.historyDisplayValue}>{suggestion.displayValue}</span>
						<span className={classNames.historySecondaryText}>{suggestion.secondaryText}</span>
					</Link>
					<span className={classNames.historyDeleteIcon}>
						<IconButton
							iconProps={historyProps?.deleteHistoryItemIcon}
							onClick={() => onHistoryDeleteClick(suggestion.itemId)}
							title="DeleteHistory"
							ariaLabel="DeleteHistory"
						/>
					</span>
				</div>
			);
		}
	};

	const onCallOutDismiss = () => {
		setIsCallOutVisible(false);
	};

	const onChange = (event?: React.ChangeEvent<HTMLInputElement> | undefined, newValue?: string | undefined) => {
		setCalloutTitle("");
		setShowLoader(false);
		setSuggestionClicked(false);
		setQuery(newValue || "");
		setSearchQuery(newValue);
	};

	React.useEffect(() => {
		if (props.onChange) {
			const { cancel } = Axios.CancelToken.source();
			const timeOutId = setTimeout(async () => {
				if (props.onChange && !suggestionClicked) {
					props.onChange(undefined, query);
				}
			}, props.debounceTime || 0);
			return () => {
				cancel("No longer latest query");
				clearTimeout(timeOutId);
			};
		}
	}, [query]);

	const onSearch = (newValue: string) => {
		hideSuggestions();
		if (!!onSearchProp) {
			onSearchProp(newValue);
		}
	};

	function onHistoryClick(): void {
		if (historyProps) {
			loggerService?.trackEvent(Globals.Telemetry.Events.ON_PERSONAL_HISTORY_CLICK);
			setShowLoader(true);
			setCalloutTitle(historyProps.calloutTitle);
			historyProps.onHistoryButtonClicked();
		}
	}

	return (
		<div>
			<div ref={textInput} className={props.className}>
				<SearchBox
					{...props}
					autoComplete="off"
					spellCheck={false}
					onChange={onChange}
					onFocus={onFocus}
					onKeyDown={onKeyDown}
					onSearch={onSearch}
					value={searchQuery}
					disabled={isSearching}
				/>
				<RenderIf condition={historyProps !== null}>
					<IconButton
						className={classNames.historyButton}
						iconProps={historyProps?.historyIcon}
						onClick={onHistoryClick}
						title="SearchHistory"
						ariaLabel="SearchHistory"
					/>
				</RenderIf>
			</div>

			<RenderIf
				condition={
					(showLoader && isLoading) || (suggestions !== undefined && suggestions.length > 0 && isCallOutVisible)
				}
			>
				<Callout
					styles={typeAheadCalloutStyle}
					isBeakVisible={false}
					target={textInput.current}
					onDismiss={onCallOutDismiss}
					directionalHint={DirectionalHint.bottomLeftEdge}
					directionalHintForRTL={DirectionalHint.bottomRightEdge}
					setInitialFocus={isCalloutFocussed}
					doNotLayer={true}
				>
					<RenderIf condition={calloutTitle !== undefined && calloutTitle.length > 0}>
						<div className={classNames.calloutTitle}>{calloutTitle}</div>
					</RenderIf>
					{renderProgressIndicator()}
					<FocusZone
						direction={FocusZoneDirection.bidirectional}
						handleTabKey={FocusZoneTabbableElements.all}
						id="focusZoneSuggestions"
						componentRef={focusZoneRef}
					>
						{renderSuggestions()}
					</FocusZone>
				</Callout>
			</RenderIf>
		</div>
	);
};
