import { IAutoCompleteSearchBoxStyleProps, IAutoCompleteSearchBoxStyles } from "./AutoCompleteSearchBox.types";

const GlobalClassNames = {
	root: "mse-AutoCompleteSearchBox",
  suggestion: "mse-AutoCompleteSearchBox-suggestion",
  suggestionLink: "mse-AutoCompleteSearchBox-suggestion-link",
  suggestionCallout: "mse-AutoCompleteSearchBox-suggestion-callout",
  historyButton: "mse-AutoCompleteSearchBox-shistory-button",
  calloutTitle: "mse-AutoCompleteSearchBox-callout-title",
  historyDisplayValue: "mse-AutoCompleteSearchBox-history-display-value",
  historySecondaryText: "mse-AutoCompleteSearchBox-history-secondary-text",
  historyDeleteIcon: "mse-AutoCompleteSearchBox-history-delete-icon"
};

export const getStyles = (props: IAutoCompleteSearchBoxStyleProps): IAutoCompleteSearchBoxStyles => {
	const classNames = GlobalClassNames;

	return {
		root: [
			classNames.root,
			{
			}
		],
    historyButton: [
			classNames.historyButton,
			{
        position: "absolute",
        top: "0px",
        zIndex: "3",
        right: "30px",
        marginTop: "3px"
			}
		],
    suggestionCallout: [
			classNames.suggestionCallout,
			{
			}
		],
    calloutTitle: [
			classNames.calloutTitle,
			{
        fontSize: "18px",
        lineHeight: "24px",
        background: "linear-gradient(112.32deg, #3F98BD 7.52%, #52BDC2 90.36%)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        paddingLeft: "20px",
			}
		],
    historyDisplayValue: [
			classNames.historyDisplayValue,
			{
        color: "#000",
        fontWeight: "400",
        fontSize: "12px",
        display: "inline-block",
        minWidth: "200px"
			}
		],
    historySecondaryText: [
			classNames.historySecondaryText,
			{
        color: "#999",
        fontWeight: "400",
        fontSize: "12px",
        paddingLeft: "10px",
        display: "inline-block",
        minWidth: "50px"
			}
		],
    historyDeleteIcon: [
			classNames.historyDeleteIcon,
			{
        paddingLeft: "10px",
        width: "12px",
        height: "12px"
			}
		],
    suggestion: [
			classNames.suggestion,
			{
        width: "100%",
        float: "left",
        "&:focus, &:hover": {
          backgroundColor: "#F2F2F2",
          textDecoration: "none",
          color: "#000",
          boxShadow: "none !important",
          outline: "none !important"
				}
			}
		],
    suggestionLink: [
			classNames.suggestionLink,
			{
        color: "#000",
        float: "left",
        padding: "5px",
        background: "none",
        border: "none",
        textAlign: "left",
        paddingLeft: "20px",
        "&:focus, &:hover, &:active": {
          backgroundColor: "#F2F2F2",
          textDecoration: "none",
          color: "#000",
          boxShadow: "none !important",
          outline: "none !important"
				},
      }
		]
	};
};
