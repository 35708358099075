import { ITheme } from "office-ui-fabric-react";
import { IContextualMenuStyles } from "office-ui-fabric-react/lib/ContextualMenu";
import { IDatePickerStyles } from "office-ui-fabric-react/lib/DatePicker";
import { IDateFilterStyleProps, IDateFilterStyles } from "./DateFilter.types";

const GlobalClassNames = {
	root: "mse-DateFilter",
	button: "mse-DateFilter-button",
	label: "mse-DateFilter-Label",
	contextualMenuLabel: "mse-DateFilter-ContextualMenuLabel",
	datesContainer: "mse-DateFilter-DatesContainer",
	datesButton: "mse-DateFilter-DatesButton"
};

export const getStyles = (props: IDateFilterStyleProps): IDateFilterStyles => {
	const { className, theme } = props;
	const classNames = GlobalClassNames;

	return {
		root: [classNames.root, {
			">span": {
				fontWeight: "normal",
				fontSize: "12px",
				paddingBottom: "0"
			}
		}, className],
		button: [classNames, {
			display: "block",
			width: "100%"
		}],
		label: [classNames.label, {
			fontFamily: "Segoe UI",
			fontStyle: "normal",
			fontWeight: "400",
			fontSize: "12px",
			lineHeight: "16px",
			color: theme.semanticColors.bodySubtext,
			textAlign: "left"
		}],
		datesContainer: [
			classNames.datesContainer,
			{ borderBottom: "1px solid #ccc", marginBottom: "10px" }
		],
		datesButton: [
			classNames.datesButton,
			{
				margin: "0px 10px 10px 10px",
				width: "180px"
			}
		],
		contextualMenuLabel: [
			classNames.contextualMenuLabel,
			{
				fontFamily: theme.fonts.medium.fontFamily,
				fontStyle: "normal",
				fontWeight: "400",
				fontSize: "12px",
				lineHeight: "16px",
				color: "#605e5c",
				textAlign: "left",
				marginLeft: "5px",
				overflow: "hidden",
				textOverflow: "ellipsis",
				whiteSpace: "nowrap"
			}
		],
	};
};

export const datePickerStyles: Partial<IDatePickerStyles> = {
	root: {
		margin: "10px"
	}
};

export const contextualMenuStyles = (theme: ITheme | undefined): Partial<IContextualMenuStyles> => {
	return {
		container: {
			justifyContent: "left",
			alignItems: "center",
			textAlign: "left"
		},
		root: {
			backgroundColor: "#FFF",
			borderRadius: "3px",
			borderColor: "#fff",
			marginTop: "3px",
			width: "200px",
			padding: "2px",
			"&:hover": {
				backgroundColor: "#FFF"
			},
			">span": {
				justifyContent: "space-between"
			}
		},
		title: {
			fontWeight: 400,
			color: theme?.semanticColors?.bodySubtext
		}
	}
};
