import { ICacheObject } from "../models/CacheObject";

export class CacheUtility {
	private static readonly DEFAULT_EXPIRATION: number = 24 * 60 * 60; // 1 day in seconds
	
	/**
	 * Retrieves item from the cache.
	 *
	 * @param key - Key of the cache entry
	 */
	public static get<T>(key: string): T | undefined {
		// retrieve item from storage
		const storageItem = localStorage.getItem(key);

		if (!storageItem) {
			// if item is falsey, return undefined
			return undefined;
		}

		// parse the data
		const cacheItem = JSON.parse(storageItem) as ICacheObject;

		if (cacheItem.expiresOn && cacheItem.expiresOn < Date.now()) {
			// if item is expired, return undefined
			return undefined;
		}

		// return item's data
		return cacheItem.data as T;
	}

	/**
	 * Adds or replaces an item in the cache
	 *
	 * @param key - Key of the cache entry
	 * @param value - Value for the cache entry
	 * @param timeToLive - Time to live until expiring. Can be a number (in seconds) or a Date object.
	 */
	 public static set<T>(key: string, value: T, timeToLive?: number | Date): void {
		// set the expiration date to either:
		// - the passed Date
		// - current Date + passed number or default number in seconds
		const expiresOn =
			typeof timeToLive === "number"
				? Date.now() + timeToLive * 1000
				: timeToLive
				? timeToLive.getTime()
				: Date.now() + CacheUtility.DEFAULT_EXPIRATION * 1000;

		// create a cache object
		const cacheItem: ICacheObject = {
			data: value,
			expiresOn: expiresOn
		};

		// add or update item in storage
		localStorage.setItem(key, JSON.stringify(cacheItem));
	}
}