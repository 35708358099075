const SymbolsFilePath: string = "/svg-symbols.svg";
const Symbols = {
  Icons: {
    IconClear: `${SymbolsFilePath}#ico-clear`,
    IconTooltip: `${SymbolsFilePath}#ico-tooltip`,
    IconPerson: `${SymbolsFilePath}#ico-person`,
    IconPublications: `${SymbolsFilePath}#ico-publications`,
    IconBack: `${SymbolsFilePath}#ico-back`,
    IconWorld: `${SymbolsFilePath}#ico-world`,
  }
};

export default Symbols;