import * as React from "react";
import { LoadingShimmer } from "../LoadingShimmer";
import { IInfiniteScrollProps } from "./InfiniteScroll.types";

function isBottom(ref: React.RefObject<HTMLDivElement>) {
	if (!ref.current) {
		return false;
	}
	return ref.current.getBoundingClientRect().bottom <= window.innerHeight;
}

export const InfiniteScrollBase: React.FC<IInfiniteScrollProps> = ({
	onBottomHit,
	isLoading,
	hasMoreData,
	currentPage,
	children
}) => {
	const contentRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const onScroll = () => {
			if (!isLoading && hasMoreData && isBottom(contentRef)) {
				onBottomHit(currentPage);
			}
		};
		document.addEventListener("scroll", onScroll);
		return () => document.removeEventListener("scroll", onScroll);
	}, [onBottomHit, isLoading, hasMoreData]);

	return (
		<div ref={contentRef}>
			{children}
			{isLoading && <LoadingShimmer />}
		</div>
	);
};
