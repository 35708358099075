import * as React from "react";
import Globals from "../../Globals";
import { SearchServiceContext, useApiAccessTokenProvider } from "../../models";
import { SearchScope } from "../../models/SearchScope";
import { createSearchService } from "../../services";
import { LoggerService, useLoggerService } from "../../services/logger";
import { searchReducer } from "./search.reducer";
import { ISearchState, SearchAction } from "./search.types";

const initialSearchState: ISearchState = {
	hasSubmittedQuery: false,
	isSearching: false,
	queryText: "",
	includeSuggestions: true,
	scope: SearchScope.All,
	filters: [Globals.DefaultProfileFilter.filter],
	searchResult: {
		items: [],
		moreResultsAvailable: false,
		currentPage: 0,
		expandedQuery: "",
		originalQuery: "",
		roundedCount: false,
		totalCount: 0,
		isQueryModificationResult: false,
		queryModification: {
			enabled: false
		}
	},
	page: 0,
	keepResults: false,
	addDefaultProfileFilter: true,
	error: undefined,
	suggestionResponse: undefined,
	isFetchingSuggestions: false,
	queryModification: {
		isQueryModificationRequest: false,
		executeQueryModificationOnSuggestionComplete: false,
		queryModificationOriginalQueryText: ""
	}
};

export const SearchContext = React.createContext({
	state: initialSearchState,
	dispatch: (action: SearchAction) => { } // tslint:disable-line:no-empty
});

export const SearchProvider = ({ children }: React.PropsWithChildren<{}>) => {
	const [state, dispatch] = React.useReducer(searchReducer, initialSearchState);
	const apiAccessTokenProvider = useApiAccessTokenProvider();
	const loggerService = useLoggerService() ?? new LoggerService({});

	const searchService = createSearchService(loggerService, apiAccessTokenProvider!, false);

	return (
		<SearchServiceContext.Provider value={searchService}>
			<SearchContext.Provider value={{ state, dispatch }}>{children}</SearchContext.Provider>
		</SearchServiceContext.Provider>
	);
};
