import { Dropdown } from "office-ui-fabric-react";
import { classNamesFunction } from "office-ui-fabric-react/lib/Utilities";
import * as React from "react";
import { useTheme } from "../../../models/contexts/ThemeContext";
import { IFilterDropdownOption } from "../../../models/search/FilterDropdownOption";
import { dropdownStyles } from "./TextFilter.styles";
import { ITextFilterProps, ITextFilterStyleProps, ITextFilterStyles } from "./TextFilter.types";
import { useTextFilter } from "./UseTextFilter";

const getClassNames = classNamesFunction<ITextFilterStyleProps, ITextFilterStyles>();

export const TextFilterBase: React.FC<ITextFilterProps> = (props: ITextFilterProps) => {
	const { displayName, multivalue } = props;

	// Styling initialization
	const { className, styles } = props;
	const theme = useTheme();
	const classNames = getClassNames(styles, { className, theme: theme! });

	const [dropdownOptions, selectedOptions, isDisabled, onRenderDropdownOption, onChangeFilter, onChangeFilters] =
		useTextFilter(props, classNames);

	if (multivalue) {
		return (
			<Dropdown
				placeholder="All"
				disabled={isDisabled}
				onRenderLabel={() => <span className={classNames.dropdownLabel}>{displayName}</span>}
				onRenderOption={onRenderDropdownOption}
				onChange={(ev, item) => onChangeFilters(item as IFilterDropdownOption)}
				options={dropdownOptions}
				selectedKeys={selectedOptions.map((o) => o.key.toString())}
				className={classNames.dropdown}
				styles={dropdownStyles}
				multiSelect={true}
				calloutProps={{
					className: classNames.dropdownCallout
				}}
			/>
		);
	} else {
		return (
			<Dropdown
				placeholder="All"
				disabled={isDisabled}
				onRenderLabel={() => <span className={classNames.dropdownLabel}>{displayName}</span>}
				onRenderOption={onRenderDropdownOption}
				onChange={(ev, item) => onChangeFilter(item as IFilterDropdownOption)}
				options={dropdownOptions}
				selectedKey={selectedOptions.map((o) => o.key.toString())}
				className={classNames.dropdown}
				styles={dropdownStyles}
			/>
		);
	}
};
