import { styled } from "office-ui-fabric-react/lib/Utilities";
import { ShimmerItemBase } from "./ShimmerItem.base";
import { getStyles } from "./ShimmerItem.styles";
import { IShimmerItemProps, IShimmerItemStyleProps, IShimmerItemStyles } from "./ShimmerItem.types";

export const ShimmerItem: React.FC<IShimmerItemProps> = styled<
	IShimmerItemProps,
	IShimmerItemStyleProps,
	IShimmerItemStyles
>(ShimmerItemBase, getStyles);
