import * as React from "react";
import { useSearch } from "../../../reducers";
import { IProfileFilterProps } from "./ProfileFilter.types";

export const useProfileFilter = (
	props: IProfileFilterProps
): [
	checked: boolean,
	onCheckboxChange: (ev?: React.FormEvent, isChecked?: boolean | undefined) => void
] => {
	// Props
	const { fieldName, displayName } = props;
	const { onSelectFilters } = props;
	const { filters, removeFilter } = useSearch();

	// State
	const [checked] = React.useState<boolean>(() => {
		const filter = filters.find((f) => f.fieldName === fieldName);
		if (!filter) {
			return false;
		} else {
			return filter.value === "true";
		}
	});

	// On checkbox change
	const onCheckboxChange = (
		ev?: React.FormEvent,
		isChecked?: boolean | undefined
	): void => {
		if (!!isChecked) {
			onSelectFilters(fieldName, [
				{
					fieldName: fieldName,
					displayName: displayName,
					text: fieldName,
					key: `profilefilter-${fieldName}`,
					value: "true",
					count: -1
				}
			]);
		} else {
			removeFilter(fieldName, `profilefilter-${fieldName}`);
		}
	};

	return [checked, onCheckboxChange];
}