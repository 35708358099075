import Globals from "../../Globals";
import { IDictionary } from "../../models";
import { IUserProfileData } from "../../models/IUserProfileData";
import { CacheUtility } from "../../utils/CacheUtility";
import { IProfileService } from "./IProfileService";

export class ProfileService implements IProfileService {
  private readonly deafultHeaders = {
    "Content-Type": "application/json"
  };
	private readonly apiAccessTokenProvider: (() => PromiseLike<string>);

	constructor(
		apiAccessTokenProvider: (() => PromiseLike<string>)
	) {
		this.apiAccessTokenProvider = apiAccessTokenProvider;
	}

	public async getProfileProperties(): Promise<IDictionary> {

		// Fetch authentication token
    const accessToken = await this.apiAccessTokenProvider();

    // Fetch OBO token
    const oboResource = `https://${process.env.REACT_APP_AZUREAD_TENANT_NAME}-my.sharepoint.com`;
    const oboUrl = `${process.env.REACT_APP_WEBAPI_BASEURI}/api/Authentication/acquireobotoken?resource=${oboResource}`;
    const oboTokenResponse = await fetch(oboUrl, { headers: { ...this.deafultHeaders, Authorization: `Bearer ${accessToken}`}, method: "GET" });
    if (!oboTokenResponse.ok) { throw new Error(await oboTokenResponse.text()); }
    const oboToken = await oboTokenResponse.text();
    
    // Build profile URL
    const url = `https://${process.env.REACT_APP_AZUREAD_TENANT_NAME}-my.sharepoint.com/_api/SP.UserProfiles.PeopleManager/GetMyProperties`;

    // Check if item is in cache
    let userProfile = CacheUtility.get<IUserProfileData>(Globals.CacheKeys.PROFILE_PROPERTIES);
    if (!!userProfile?.UserProfileProperties) {
      return Object.assign({}, ...userProfile.UserProfileProperties?.map((x) => ({[x.Key]: x.Value})))
    }
    // Execute profile fetch
    const response = await fetch(url, { headers: { ...this.deafultHeaders, Accept: "application/json", Authorization: `Bearer ${oboToken}`}, method: "GET" });

    // Check response
    if (!response.ok) {
      throw new Error(await response.text());
    }

    // Return response
    userProfile = await response.json();
    if (!!userProfile) {
      CacheUtility.set<IUserProfileData>(Globals.CacheKeys.PROFILE_PROPERTIES, userProfile);
      // Filter only Imec properties
      return Object.assign({}, ...userProfile.UserProfileProperties
        ?.filter((x) => x.Key?.startsWith("Imec"))
        ?.map((x) => ({[x.Key]: x.Value})))
    } else {
      return {};
    }
	}
}