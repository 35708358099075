import { styled } from "office-ui-fabric-react/lib/Utilities";
import { LoadingShimmerBase } from "./LoadingShimmer.base";
import { getStyles } from "./LoadingShimmer.styles";
import { ILoadingShimmerProps, ILoadingShimmerStyleProps, ILoadingShimmerStyles } from "./LoadingShimmer.types";

export const LoadingShimmer: React.FC<ILoadingShimmerProps> = styled<
	ILoadingShimmerProps,
	ILoadingShimmerStyleProps,
	ILoadingShimmerStyles
>(LoadingShimmerBase, getStyles);
