import { ITheme, loadTheme } from "office-ui-fabric-react";
export const darkTheme: ITheme = {
  id: "dark",
  ...loadTheme({
    semanticColors: {
      bodyBackground: "#242424",
      bodyText: "#FFFFFF",
      bodySubtext: "#FFFFFF",
      inputIcon: "#FFFFFF"
    },
    fonts: {
      medium: {
        fontFamily: "Segoe UI",
      }
    }
  })
};
